import React, { useEffect, useState } from "react";
import BankDeposit from "../../../assets/svg/BankDep.svg";

import PaymentOptionBlock from "../../../components/PaymentOptionBlock/PaymentOptionBlock";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale } from "../../../services/variables";
import ConvertToArabicNumbers from "../../../services/utils";

function DownpaymentOptionScreen(props) {
  const [aboveMinimumAmount, setAboveMinimumAmount] = useState(null);
  useEffect(() => {
    const productAmount = Number(props.tokenObj.amount);
    if (productAmount <= 1000) {
      setAboveMinimumAmount(false);
    } else {
      setAboveMinimumAmount(true);
    }
  }, [props]);
  return (
    <div class="pt-6 lg:pt-0" dir={locale ? "ltr" : "rtl"}>
      <div class="mb-8 space-y-4">
        <p
          class={
            locale
              ? "text-Title2_RG text-Neutrals-black font-normal font-arch text-left tracking-small"
              : "text-Title2_RG text-Neutrals-black font-normal font-Arabic text-right tracking-small"
          }
        >
          {selectedlang.onboarding.registrationFunnel.registration0.header}
        </p>
        <p
          class={
            locale
              ? "text-Body2_RG tracking-small text-Neutrals-black font-normal font-inter text-left"
              : "text-Body2_RG tracking-small text-Neutrals-black font-normal font-Arabic text-right"
          }
        >
          {selectedlang.onboarding.registrationFunnel.registration0.text}
          <br />
          <span class="font-semibold">
            {`${props.tokenObj.productName} (${
              locale
                ? props.tokenObj.amount
                : ConvertToArabicNumbers(props.tokenObj.amount)
            } ${locale ? "EGP" : "جنيه"})`}
          </span>
        </p>
      </div>
      <div
        className={
          locale ? "w-full md:w-3/4 font-inter" : "w-full md:w-3/4 font-Arabic"
        }
        dir={locale ? "ltr" : "rtl"}
      >
        <div class={`w-full ${locale ? "font-inter" : "font-Arabic"}`}>
          <PaymentOptionBlock
            onClick={() => {
              props.changeDownpaymentFlag(false);
              props.changeCurrentPage(2);
              props.navigate("plan");
            }}
            active
            // id={0}
            title={
              selectedlang.onboarding.downpaymentOptions.fullAmountCredit.title
            }
            subtitle={
              selectedlang.onboarding.downpaymentOptions.fullAmountCredit
                .subtitle
            }
            icon={BankDeposit}
          />
        </div>
        {aboveMinimumAmount ? (
          <div class={`w-full ${locale ? "font-inter" : "font-Arabic"}`}>
            <PaymentOptionBlock
              onClick={() => {
                props.changeDownpaymentFlag(true);
                props.navigate("plan");
              }}
              active
              // id={1}
              title={
                selectedlang.onboarding.downpaymentOptions.partCredit.title
              }
              subtitle={
                selectedlang.onboarding.downpaymentOptions.partCredit.subtitle
              }
              icon={BankDeposit}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default DownpaymentOptionScreen;
