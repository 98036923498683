import React, { useEffect, useState } from "react";
import moment from "moment";
import OctoMCQ from "../OctoMCQ/OctoMCQ";
import Calendar from "../../assets/svg/Disburse/CalendarDisburse.svg";
import lastPay from "../../assets/svg/Disburse/Flag.svg";
import fees from "../../assets/svg/Disburse/Calculator.svg";
import Monthly from "../../assets/svg/Disburse/Calendarsync.svg";
import total from "../../assets/svg/Disburse/Coins.svg";
import Credit from "../../assets/svg/Disburse/MoneyCoins.svg";
import { selectedlang } from "../../services/selectedlanguage";
import { locale } from "../../services/variables";
import ConvertToArabicNumbers from "../../services/utils";
// import "moment/locale/ar";
// import "moment/locale/en-gb";
function OctoPaymentPlan({
  availablePlans,
  selectedType,
  setSelectedType,
  setSelectedPlan,
  selectedPlan,
}) {
  useEffect(() => {
    if (locale) {
      moment.locale("en-gb");
    } else {
      moment.locale("ar");
    }
  }, []);

  const [firstPayment, setFirstPayment] = useState("");
  const [lastPayment, setLastPayment] = useState("");
  const [planFees, setPlanFees] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [creditWithFees, setCreditWithFees] = useState("");
  const [credit, setCredit] = useState("");

  useEffect(() => {
    if (selectedPlan !== null) {
      setFirstPayment(
        moment(selectedPlan.firstPayment, "YYYY-MM-DD").format("D MMM YYYY")
      );
      setLastPayment(
        moment(selectedPlan.lastPayment, "YYYY-MM-DD").format("D MMM YYYY")
      );
      setPlanFees(
        locale
          ? selectedPlan.fees.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
              " EGP"
          : ConvertToArabicNumbers(
              selectedPlan.fees.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ) + " جنيه"
      );
      setMonthlyPayment(
        locale
          ? selectedPlan.monthlyPayment
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " EGP"
          : ConvertToArabicNumbers(
              selectedPlan.monthlyPayment
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ) + " جنيه"
      );
      setCreditWithFees(
        locale
          ? selectedPlan.creditWithFees
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " EGP"
          : ConvertToArabicNumbers(
              selectedPlan.creditWithFees
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ) + " جنيه"
      );
      setCredit(
        locale
          ? selectedPlan.credit
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " EGP"
          : ConvertToArabicNumbers(
              selectedPlan.credit
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ) + " جنيه"
      );
    }
  }, [selectedPlan]);

  return (
    <div className="">
      <div className="bg-transparent">
        <div
          className={`text-Body3_B tracking-small font-medium text-Neutrals-500 ${
            locale ? " font-inter " : " font-Arabic text-right "
          } space-x-4 justify-evenly items-center my-5 `}
        >
          {selectedlang.onboarding.paymentplan.text}
        </div>
        <div
          className={`flex ${
            locale ? "flex-row" : "flex-row-reverse space-x-reverse"
          } space-x-4  items-center my-5 `}
        >
          {availablePlans.map((plan, index) => (
            <OctoMCQ
              key={index}
              variant="plans"
              buttonText={locale ? plan.id : ConvertToArabicNumbers(plan.id)}
              option={
                selectedType === plan.numberOfInstallments
                  ? "selected"
                  : "secondary"
              }
              onClick={() => {
                setSelectedType(plan.numberOfInstallments);
                setSelectedPlan(plan);
              }}
            />
          ))}
        </div>
        {selectedType && (
          <>
            <div className="w-full my-4 h-1px bg-Neutrals-100" />
            <div
              className={`text-Body3_B tracking-small font-medium text-Neutrals-500 ${
                locale ? " font-inter " : " font-Arabic text-right "
              } space-x-4 justify-evenly items-center `}
            >
              {selectedlang.onboarding.paymentplan.overviewText}
            </div>
          </>
        )}

        {selectedPlan !== null ? (
          <div className="grid w-full grid-cols-2 gap-4 py-4 md:grid-cols-3">
            <div
              className={` flex ${
                locale ? "flex-row order-1" : "flex-row-reverse order-2"
              }`}
            >
              <div className="flex items-start">
                <img alt="" src={Calendar} width={24} height={24} />
              </div>
              <div
                className={`flex flex-col  ${
                  locale ? "ml-3" : "mr-3 items-end"
                }`}
              >
                <div>
                  <p
                    className={` text-Body4_RG font-normal tracking-tight text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    }`}
                  >
                    {selectedlang.onboarding.paymentplan.first}
                  </p>
                  <p
                    className={` text-Number6_B font-medium tracking-tight text-Neutrals-750 ${
                      locale ? " font-arch " : " font-Arabic text-right "
                    }`}
                  >
                    {firstPayment}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`flex ${
                locale ? "flex-row order-2" : "flex-row-reverse order-1"
              }`}
            >
              <div className="flex items-start">
                <img alt="" src={lastPay} width={24} height={24} />
              </div>
              <div
                className={`flex flex-col  ${
                  locale ? "ml-3" : "mr-3 items-end"
                }`}
              >
                <div>
                  <p
                    className={` text-Body4_RG font-normal tracking-tight text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    }`}
                  >
                    {selectedlang.onboarding.paymentplan.last}
                  </p>
                  <p
                    className={` text-Number6_B font-medium tracking-tight text-Neutrals-750 ${
                      locale ? " font-arch " : " font-Arabic text-right "
                    }`}
                  >
                    {lastPayment}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={` flex ${
                locale ? "flex-row order-3" : "flex-row-reverse order-4"
              }`}
            >
              <div className="flex items-start">
                <img alt="" src={fees} width={24} height={24} />
              </div>
              <div
                className={`flex flex-col  ${
                  locale ? "ml-3" : "mr-3 items-end"
                }`}
              >
                <div>
                  <p
                    className={` text-Body4_RG font-normal tracking-tight text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    }`}
                  >
                    {selectedlang.onboarding.paymentplan.fees}
                  </p>
                  <p
                    className={` text-Number6_B font-medium tracking-tight text-Neutrals-750 ${
                      locale ? " font-arch " : " font-Arabic text-right "
                    }`}
                  >
                    {planFees}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={` flex ${
                locale ? "flex-row order-4" : "flex-row-reverse order-3"
              }`}
            >
              <div className="flex items-start">
                <img alt="" src={Monthly} width={24} height={24} />
              </div>
              <div
                className={`flex flex-col  ${
                  locale ? "ml-3" : "mr-3 items-end"
                }`}
              >
                <div>
                  <p
                    className={` text-Body4_RG font-normal tracking-tight text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    }`}
                  >
                    {selectedlang.onboarding.paymentplan.monthly}
                  </p>
                  <p
                    className={` text-Number6_B font-medium tracking-tight text-Neutrals-750 ${
                      locale ? " font-arch " : " font-Arabic text-right "
                    }`}
                  >
                    {monthlyPayment}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={` flex ${
                locale ? "flex-row order-5" : "flex-row-reverse order-6"
              }`}
            >
              <div className="flex items-start">
                <img alt="" src={total} width={24} height={24} />
              </div>
              <div
                className={`flex flex-col  ${
                  locale ? "ml-3" : "mr-3 items-end"
                }`}
              >
                <div>
                  <p
                    className={` text-Body4_RG font-normal tracking-tight text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    }`}
                  >
                    {selectedlang.onboarding.paymentplan.total}
                  </p>
                  <p
                    className={` text-Number6_B font-medium tracking-tight text-Neutrals-750 ${
                      locale ? " font-arch " : " font-Arabic text-right "
                    }`}
                  >
                    {creditWithFees}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`flex ${
                locale ? "flex-row order-6" : "flex-row-reverse order-5"
              }`}
            >
              <div className="flex items-start">
                <img alt="" src={Credit} width={24} height={24} />
              </div>
              <div
                className={`flex flex-col  ${
                  locale ? "ml-3" : "mr-3 items-end"
                }`}
              >
                <div>
                  <p
                    className={` text-Body4_RG font-normal tracking-tight text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    }`}
                  >
                    {selectedlang.onboarding.paymentplan.amount}
                  </p>
                  <p
                    className={` text-Number6_B font-medium tracking-tight text-Neutrals-750 ${
                      locale ? " font-arch " : " font-Arabic text-right "
                    }`}
                  >
                    {credit}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
    // <div class="">
    //   <div class="bg-white p-4 shadow-octo rounded-md">
    //     <div class={locale ? "text-left" : "text-right"}>
    //       <p class="text-Body3_B tracking-small font-inter font-medium text-Neutrals-400">
    //         {selectedlang.onboarding.paymentplan.text}
    //       </p>
    //     </div>
    //     <div class="flex flex-1 justify-evenly lg:justify-center items-center my-5 space-x-2">
    //       {availablePlans.map((plan, index) => (
    //         <OctoMCQ
    //           key={index}
    //           variant={"plans"}
    //           buttonText={plan.id}
    //           option={
    //             selectedType === plan.numberOfInstallments
    //               ? "selected"
    //               : "secondary"
    //           }
    //           onClick={() => {
    //             setSelectedType(plan.numberOfInstallments);
    //             setSelectedPlan(plan);
    //           }}
    //         />
    //       ))}
    //     </div>

    //     {selectedPlan !== null ? (
    //       <>
    //         <div class="w-full h-1px bg-Neutrals-100 my-4" />
    //         <div class="grid grid-cols-2 gap-4 w-full p-4">
    //           <div
    //             class={
    //               locale ? "flex space-x-3" : "flex flex-row-reverse gap-3"
    //             }
    //           >
    //             <div class="flex">
    //               <img alt="" src={Calendar} width={24} height={24} />
    //             </div>
    //             <div class={locale ? "" : "text-right"}>
    //               <p class="text-Body4_RG font-inter font-normal tracking-tight text-Neutrals-500">
    //                 {selectedlang.onboarding.paymentplan.first}{" "}
    //               </p>
    //               <p class="flex items-center text-Number6_B font-arch font-medium tracking-tight text-Neutrals-750">
    //                 {selectedPlan
    //                   ? moment(selectedPlan.firstPayment, "YYYY-MM-DD").locale("ar").format(
    //                       "DD. MMM. YYYY"
    //                     )
    //                   : ""}
    //               </p>
    //             </div>
    //           </div>

    //           <div
    //             class={
    //               locale ? "flex space-x-3" : "flex flex-row-reverse gap-3"
    //             }
    //           >
    //             <div class="flex items-start">
    //               <img alt="" src={lastPay} width={24} height={24} />
    //             </div>
    //             <div class={locale ? "" : "text-right"}>
    //               <p class="text-Body4_RG font-inter font-normal tracking-tight text-Neutrals-500">
    //                 {selectedlang.onboarding.paymentplan.last}{" "}
    //               </p>
    //               <p class="flex items-center text-Number6_B font-arch font-medium tracking-tight text-Neutrals-750">
    //                 {selectedPlan
    //                   ? moment(selectedPlan.lastPayment, "YYYY-MM-DD").format(
    //                       "DD. MMM. YYYY"
    //                     )
    //                   : ""}
    //               </p>
    //             </div>
    //           </div>

    //           <div
    //             class={
    //               locale ? "flex space-x-3" : "flex flex-row-reverse gap-3"
    //             }
    //           >
    //             <div class="flex items-start">
    //               <img alt="" src={fees} width={24} height={24} />
    //             </div>
    //             <div class={locale ? "" : "text-right"}>
    //               <p class="text-Body4_RG font-inter font-normal tracking-tight text-Neutrals-500">
    //                 {selectedlang.onboarding.paymentplan.fees}
    //               </p>
    //               <p class="flex items-center text-Number6_B font-arch font-medium tracking-tight text-Neutrals-750">
    //                 {selectedPlan.fees} EGP
    //               </p>
    //             </div>
    //           </div>

    //           <div
    //             class={
    //               locale ? "flex space-x-3" : "flex flex-row-reverse gap-3"
    //             }
    //           >
    //             <div class="flex items-start">
    //               <img alt="" src={Monthly} width={24} height={24} />
    //             </div>
    //             <div class={locale ? "" : "text-right"}>
    //               <p class="text-Body4_RG font-inter font-normal tracking-tight text-Neutrals-500">
    //                 {selectedlang.onboarding.paymentplan.monthly}{" "}
    //               </p>
    //               <p class="text-Number6_B font-arch font-medium tracking-tight text-Neutrals-750">
    //                 {selectedPlan.monthlyPayment.toFixed(
    //                   selectedPlan.monthlyPayment % 1 === 0 ? 0 : 2
    //                 )}{" "}
    //                 EGP
    //               </p>
    //             </div>
    //           </div>

    //           <div
    //             class={
    //               locale ? "flex space-x-3" : "flex flex-row-reverse gap-3"
    //             }
    //           >
    //             <div class="flex items-start">
    //               <img alt="" src={total} width={24} height={24} />
    //             </div>
    //             <div class={locale ? "" : "text-right"}>
    //               <p class="text-Body4_RG font-inter font-normal tracking-tight text-Neutrals-500">
    //                 {selectedlang.onboarding.paymentplan.total}{" "}
    //               </p>
    //               <p class="flex items-center text-Number6_B font-arch font-medium tracking-tight text-Neutrals-750">
    //                 {selectedPlan.creditWithFees} EGP
    //               </p>
    //             </div>
    //           </div>

    //           <div
    //             class={
    //               locale ? "flex space-x-3" : "flex flex-row-reverse gap-3"
    //             }
    //           >
    //             <div class="flex items-start">
    //               <img alt="" src={Credit} width={24} height={24} />
    //             </div>
    //             <div class={locale ? "" : "text-right"}>
    //               <p class="text-Body4_RG font-inter font-normal tracking-tight text-Neutrals-500">
    //                 {selectedlang.onboarding.paymentplan.amount}{" "}
    //               </p>
    //               <p class="flex items-center text-Number6_B font-arch font-medium tracking-tight text-Secondary-500">
    //                 {selectedPlan.credit} EGP
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       </>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    // </div>
  );
}
export default OctoPaymentPlan;
