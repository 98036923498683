import { Button } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { selectedlang } from "../../../services/selectedlanguage";
import OtpInput from "react-otp-input";
import LockSvg from "../../../assets/svg/Code_lock.svg";
import OctoNumpad from "../../../components/OctoNumpad/OctoNumpad";
import { OAG_API_URL, locale } from "../../../services/variables";
import { showErrorMessage } from "../../../services/utils";
import OctoButton from "../../../components/OctoButton/OctoButton";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

const saved_phone = sessionStorage.getItem("phone");

function LoginPin(props) {
  const [pin, setPin] = useState("");
  const [numpadUsed, setNumpadUsed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const handlePinChange = (pin) => {
    setPin(pin);
  };
  const handlePinChangeNumpad = (pin) => {
    setNumpadUsed(true);
    setPin(pin);
  };
  useEffect(() => {
    props.changeCurrentPage(1);
  }, []);
  useEffect(() => {
    const disableCheck = () => {
      if (pin.length === 6) {
        if (numpadUsed) loginOctoUser();
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    disableCheck();
  }, [pin]);

  const loginOctoUser = () => {
    setLoading(true);
    let phone = props.phone !== "" ? props.phone : saved_phone;
    axios({
      method: "post",
      url: OAG_API_URL + "user/login/",
      mode: "cors",
      data: {
        phoneNumber: "+2" + phone,
        password: pin,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        console.log(responseData.data);
        const access = responseData.data.access;
        const refresh = responseData.data.refresh;
        const decoded = access ? jwtDecode(access) : "";
        axios({
          method: "get",
          url: `${OAG_API_URL}users/${decoded.user_octoId}/profile/`,
          headers: {
            Authorization: "Bearer " + access,
            "Content-Type": "application/json",
          },
          mode: "cors",
        })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              return response.data;
            }
          })
          .then((responseData) => {
            if (
              responseData.userType !== 3 &&
              responseData.userType !== 0 &&
              responseData.status === 3
            ) {
              sessionStorage.setItem("octo-access", access);
              sessionStorage.setItem("octo-refresh", refresh);
              props.changeCurrentPage(2);
              setLoading(false);
              props.navigate("verification");
            } else {
              sessionStorage.clear();
              if (responseData.userType === 3 || responseData.userType === 0) {
                toast.error(
                  locale
                    ? "We are sorry, you cannot use this service with your current account"
                    : "نأسف, لا يمكنك استخدام هذه الخدمة بحسابك الحالي",
                  {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    bodyClassName: locale ? "text-left" : "text-right",
                    rtl: !locale,
                  }
                );
              } else {
                toast.error(
                  locale
                    ? "Account not activated, please visit our nearest hub to complete activation"
                    : "حسابك غير مفعل, الرجاء زيارة اقرب فرع لتفعيل حسابك",
                  {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    bodyClassName: locale ? "text-left" : "text-right",
                    rtl: !locale,
                  }
                );
              }
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          })
          .catch((error) => {
            setPin("");
            showErrorMessage(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        setPin("");
        showErrorMessage(error);
      });
  };

  return (
    <div class="pt-6 lg:pt-0">
      <div class="w-16 h-16 mb-7 items-center mx-auto">
        <img
          width={"80px"}
          height={"80px"}
          src={LockSvg}
          alt=""
          loading="lazy"
        />
      </div>
      <div
        class={`text-Title3_MD text-Neutrals-750 tracking-tight font-medium ${
          locale ? "font-arch" : "font-Arabic"
        } text-center`}
      >
        {selectedlang.login.login.header2}
      </div>
      <div class="my-8">
        <div class="my-4 justify-evenly">
          <OtpInput
            data-testid="pin-input"
            className={{
              borderBottom: "2px solid rgb(50, 80, 131,1)",
            }}
            value={pin}
            onChange={handlePinChange}
            numInputs={6}
            containerStyle={{
              width: "100%",
              "justify-content": "space-between",
            }}
            inputStyle={{
              borderBottom: "2px solid rgb(176, 190, 197,1)",
              height: 50,
              padding: 0,
              width: 44,
              "border-radius": "6px",
              color: "#0C264F",
              "font-size": "42px",
            }}
            focusStyle={{
              outline: "none",
              borderBottom: "2px solid rgb(50, 80, 131)",
            }}
            isInputNum={true}
            isInputSecure={true}
          />
        </div>
        <div class="hidden lg:block">
          <div class="my-5">
            <OctoButton
              variant="primary"
              onClick={loginOctoUser}
              disabled={disabled}
              buttonText={selectedlang.login.login.continueButton}
              Loading={loading}
            />
          </div>
          <div class="my-5">
            <OctoButton
              variant={"ghost-sm"}
              onClick={() => props.history.push("/forgot")}
              buttonText={selectedlang.login.login.forgotButton}
            />
          </div>
        </div>
        <div class="mt-100">
          <OctoNumpad onChange={handlePinChangeNumpad} value={pin} hasForgot />
        </div>
      </div>
    </div>
  );
}

export default LoginPin;
