import React, { useEffect, useState } from "react";

import OctoButton from "../../../components/OctoButton/OctoButton";
import OctoInput from "../../../components/OctoInput/OctoInput";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale } from "../../../services/variables";
import { ConvertToEnglishNumbers } from "../../../services/utils";

const LoginPhone = (props) => {
  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    props.changeCurrentPage(0);
  }, []);
  useEffect(() => {
    const disableCheck = () => {
      if (
        phone !== "" &&
        phone.length === 11 &&
        (phone.match(/^\d+$/) !== null ||
          ConvertToEnglishNumbers(phone).match(/^\d+$/) !== null)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    disableCheck();
  }, [phone]);

  const changePhone = (e) => {
    setPhone(e.target.value);
  };

  const movetoPin = () => {
    props.changePhone(phone);
    props.changeCurrentPage(1);

    props.navigate("pin");
  };
  return (
    <div class="pt-6 lg:pt-0">
      <p
        class={
          locale
            ? "text-2.5xl text-Neutrals-750 font-normal font-arch text-left mb-1 tracking-small"
            : "text-2.5xl text-Neutrals-700 font-normal font-Arabic text-right mb-2"
        }
      >
        {selectedlang.login.login.header}
      </p>

      <p
        class={
          locale
            ? "text-left text-Body1_RG text-Neutrals-black font-normal font-inter tracking-tight"
            : "text-right text-lg text-Neutrals-700 font-normal font-Arabic"
        }
      >
        {selectedlang.login.login.text}
      </p>
      <div class="my-8">
        <div class="my-4">
          <OctoInput
            variant={"primary"}
            onChange={changePhone}
            value={phone}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !disabled) {
                movetoPin();
              }
            }}
            placeholder={selectedlang.login.login.inputFields.placeholderPhone}
            loadLogo="Mobile"
          />
        </div>
        <div class="mt-6">
          <OctoButton
            variant={"primary"}
            onClick={movetoPin}
            disabled={disabled}
            buttonText={selectedlang.login.login.continueButton}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPhone;
