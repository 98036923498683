import React, { useEffect, useState } from "react";

import OtherDetails from "./otherDetails";
import RegisterOTP from "./otp";
import RegisterPin from "./PIN";
import RegisterPhone from "./signup";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import OctoHeader from "../../../components/OctoHeader/OctoHeader";
import { locale } from "../../../services/variables";
import EmploymentDetails from "./employmentDetails";
import ErrorScreen from "../../error/ErrorScreen";
import RegisterLimit from "./creditLimitLoading";
import RegisterOverview from "./creditLimitOverview";

function RegisterIndexScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [phone, setPhone] = useState("");
  const [birthdate, setBirthDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [income, setIncome] = useState("");
  const [employementType, setEmployementType] = useState("");
  const [yoe, setYOE] = useState("");

  const prevPage = () => {
    navigate(-1);
  };

  //BASIC HANDLERS
  const onChangePhone = (phone) => {
    setPhone(phone);
  };
  const onChangeFname = (fname) => {
    setFirstName(fname);
  };
  const onChangeLname = (lname) => {
    setLastName(lname);
  };
  const onChangeEmail = (email) => {
    setEmail(email);
  };

  ////EMPLOYMENT HANDLERS
  const onChangeIncome = (income) => {
    setIncome(income);
  };

  const onChangeEmploymentType = (type) => {
    setEmployementType(type);
  };

  const onChangeYOE = (yoe) => {
    setYOE(yoe);
  };

  const onChangeBirthDate = (value) => {
    setBirthDate(value);
  };

  return props.tokenFlag === true ? (
    <>
      <OctoHeader
        variant={"onboarding"}
        onBack={prevPage}
        logo={props.merchant.icon}
      />
      <div
        class={
          location.pathname !== "/register/start/"
            ? "flex-1 lg:grid lg:grid-cols-2"
            : "flex-1 lg:grid lg:grid-cols-1"
        }
      >
        <div class={locale ? "col-span-1" : "col-span-1 order-last"}>
          <Routes>
            <Route
              path="signup"
              element={
                <RegisterPhone
                  navigate={navigate}
                  setPhone={onChangePhone}
                  setFirstName={onChangeFname}
                  setLastName={onChangeLname}
                  setEmail={onChangeEmail}
                  setBirthDate={onChangeBirthDate}
                  changeCurrentPage={props.changeCurrentPage}
                />
              }
            ></Route>
            <Route
              path="otp"
              element={
                <RegisterOTP
                  navigate={navigate}
                  phone={phone}
                  changeCurrentPage={props.changeCurrentPage}
                />
              }
            />
            <Route
              path="pin"
              element={
                <RegisterPin
                  tokenObj={props.tokenObj}
                  selectedPlan={props.selectedPlan}
                  changeCurrentPage={props.changeCurrentPage}
                  navigate={navigate}
                  birthdate={birthdate}
                  phone={phone}
                  fname={firstName}
                  lname={lastName}
                  email={email}
                />
              }
            />
            <Route
              path="employment"
              element={
                <EmploymentDetails
                  changeCurrentPage={props.changeCurrentPage}
                  navigate={navigate}
                  onChangeIncome={onChangeIncome}
                  onChangeEmploymentType={onChangeEmploymentType}
                  onChangeYOE={onChangeYOE}
                />
              }
            />
            <Route
              path="questionnaire"
              element={
                <OtherDetails
                  changeCurrentPage={props.changeCurrentPage}
                  income={income}
                  employementType={employementType}
                  yoe={yoe}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="credit"
              element={
                <RegisterLimit
                  changeCurrentPage={props.changeCurrentPage}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="confirm"
              element={
                <RegisterOverview
                  changeCurrentPage={props.changeCurrentPage}
                  navigate={navigate}
                />
              }
            />
          </Routes>
        </div>
        <div></div>
      </div>
    </>
  ) : (
    <ErrorScreen></ErrorScreen>
  );
}

export default RegisterIndexScreen;
