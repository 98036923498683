import axios from "axios";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import PinSvg from "../../../assets/svg/PIN.svg";
import OctoButton from "../../../components/OctoButton/OctoButton";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale, OAG_API_URL } from "../../../services/variables";
import { showErrorMessage } from "../../../services/utils";

const saved_phone = sessionStorage.getItem("phone");

function RegisterOTP(props) {
  const [Phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");

  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleChange = (otp) => {
    if (otp.length === 6) {
      sendAmplitudeEvent("KASHIER_OTP_INPUT", {});
      setContinueDisabled(false);
    } else {
      setContinueDisabled(true);
    }
    setOTP(otp);
  };
  const tick = () => {
    if (timerActive && timer !== 0) {
      setTimer(timer - 1);
    }
  };
  const [ticker, setTicker] = useState(
    setTimeout(() => {
      tick();
    }, 1000)
  );

  useEffect(() => {
    props.changeCurrentPage(1);
    if (props.phone) {
      setPhone(props.phone);
    }
    return () => {
      clearInterval(ticker);
    };
  }, []);
  useEffect(() => {
    if (timer === 0) {
      setTimerActive(false);
      setResendDisabled(false);
    }
  }, [timer]);
  const resendCode = () => {
    let phone = Phone !== "" ? Phone : saved_phone;
    setTimer(240);
    setTimerActive(true);
    setResendDisabled(true);
    axios({
      method: "post",
      url: OAG_API_URL + "verification/sms/request/",

      mode: "cors",
      data: {
        phoneNumber: "+2" + phone,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        sendAmplitudeEvent("KASHIER_OTP_RESEND", {});

        console.log(responseData);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const verifyOTP = () => {
    setLoading(true);
    let phone = Phone !== "" ? Phone : saved_phone;
    axios({
      method: "post",
      url: OAG_API_URL + "verification/sms/verify/",
      mode: "cors",
      data: {
        phoneNumber: "+2" + phone,
        token: otp,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setLoading(false);
        console.log(responseData.data);
        sendAmplitudeEvent("KASHIER_OTP_SUCCESS", {});

        toast.success("Phone verified successfully", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        props.changeCurrentPage(2);
        props.navigate("pin");
      })
      .catch((error) => {
        setLoading(false);
        sendAmplitudeEvent("KASHIER_OTP_FAIL", {});
        showErrorMessage(error);
      });
  };

  return (
    <div class="pt-6 lg:pt-0">
      <div
        class={
          locale
            ? "block lg:flex lg:flex-row-reverse lg:items-center"
            : "block lg:flex lg:items-center"
        }
      >
        <div class="items-center flex justify-center">
          <img loading="lazy" src={PinSvg} alt="pin" />
        </div>

        <p
          class={
            locale
              ? "text-Title3_MD text-Neutrals-750 lg:flex-1 lg:text-Title2_RG font-medium tracking-tight mb-1 font-arch text-center lg:text-left"
              : "text-Title3_MD text-Neutrals-750 lg:flex-1 lg:text-Title2_RG font-normal font-Arabic tracking-tight mb-1 text-center lg:text-right"
          }
        >
          {selectedlang.onboarding.registrationFunnel.registration3.header}
        </p>
      </div>
      <p
        class={
          locale
            ? "text-center lg:text-left text-Body2_RG lg:text-Body1_RG text-Neutrals-black font-normal tracking-tight font-inter mb-6"
            : "text-center lg:text-right text-Body2_RG text-Neutrals-black font-normal tracking-tight font-Arabic mb-6"
        }
      >
        {selectedlang.onboarding.registrationFunnel.registration3.text}
      </p>
      <div class="my-6">
        <div class="my-6 justify-evenly">
          <OtpInput
            data-testid="otp-input"
            className={{
              borderBottom: "2px solid rgb(207, 216, 220)",
            }}
            value={otp}
            onChange={handleChange}
            numInputs={6}
            containerStyle={{
              width: "100%",
              "justify-content": "space-between",
            }}
            inputStyle={{
              borderBottom: "2px solid rgb(176, 190, 197)",
              height: 50,
              padding: 0,
              width: 44,
              "border-radius": "4px",
              color: "#325083",
              "font-size": "42px",
            }}
            focusStyle={{
              outline: "none",
              borderColor: "#7082A4",
              borderBottom: "2px solid rgb(50, 80, 131)",
            }}
            isInputNum={true}
            isInputSecure={true}
          />
        </div>
        <div class={locale ? "flex w-full" : "flex flex-row-reverse w-full"}>
          <div class="w-full mb-8 lg:w-2/3" dir={locale ? "ltr" : "rtl"}>
            <OctoButton
              variant="primary"
              onClick={verifyOTP}
              disabled={continueDisabled}
              buttonText={
                selectedlang.onboarding.registrationFunnel.registration3
                  .continueButton
              }
              Loading={loading}
            />
            <p
              class={
                locale
                  ? "font-medium font-inter text-Neutrals-750 text-Body3_B tracking-small pt-6 text-center lg:text-left"
                  : "font-medium font-Arabic text-Neutrals-750 text-Body3_B tracking-small pt-6 text-center lg:text-right"
              }
            >
              {
                selectedlang.onboarding.registrationFunnel.registration3
                  .notReceived
              }
            </p>
            <OctoButton
              variant="ghost-sm"
              test_id={"continue-btn"}
              onClick={() => {
                sendAmplitudeEvent("KASHIER_OTP_CONTINUE", {});
                resendCode();
              }}
              customColor={timer > 0 ? "#090A0C" : "#116DCF"}
              buttonText={
                timer > 0
                  ? selectedlang.onboarding.registrationFunnel.registration3
                      .requestText +
                    " " +
                    timer +
                    " " +
                    selectedlang.onboarding.registrationFunnel.registration3
                      .secondsText
                  : selectedlang.onboarding.registrationFunnel.registration3
                      .resendCodeButton
              }
              disabled={resendDisabled}
            />
          </div>
        </div>
        {/* <div class="my-5 text-center font-helv">
          <p
            class={
              locale
                ? "font-medium font-inter text-Neutrals-750 text-Body3_B tracking-small"
                : "font-medium font-Arabic text-Neutrals-750 text-Body3_B tracking-small"
            }
          >
            {
              selectedlang.onboarding.registrationFunnel.registration3
                .notReceived
            }
          </p>
          <OctoButton
            variant="ghost-sm"
            test_id={"continue-btn"}
            onClick={() => {
              sendAmplitudeEvent("KASHIER_OTP_CONTINUE", {});
              resendCode();
            }}
            customColor={timer > 0 ? "#090A0C" : "#116DCF"}
            buttonText={
              timer > 0
                ? selectedlang.onboarding.registrationFunnel.registration3
                    .requestText +
                  " " +
                  timer +
                  " " +
                  selectedlang.onboarding.registrationFunnel.registration3
                    .secondsText
                : selectedlang.onboarding.registrationFunnel.registration3
                    .resendCodeButton
            }
            disabled={resendDisabled}
          />
        </div> */}
      </div>
    </div>
  );
}

export default RegisterOTP;
