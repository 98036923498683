import React from "react";
import propTypes from "prop-types";
import redInfo from "../../assets/svg/redInfo.svg";
import blueInfo from "../../assets/svg/blueInfo.svg";
import meter from "../../assets/svg/GaugeDis.svg";
import GrayInfo from "../../assets/svg/GrayInfo.svg";
import TimeReverse from "../../assets/svg/TimeReverse.svg";
import BlueMoney from "../../assets/svg/MoneyCoinsBlue.svg";
import { locale } from "../../services/variables";
import ConvertToArabicNumbers from "../../services/utils";

function OctoDisclaimer({
  variant,
  late,
  bodyText,
  limit,
  chosen,
  fees,
  option,
  height,
}) {
  const renderDisclaimer = () => {
    switch (variant) {
      case "normal":
        return (
          <div class="pb-4 px-4">
            <div
              height={height !== "" ? height : 48}
              class={
                late
                  ? "bg-Destructive-100 rounded-md w-full"
                  : "bg-Secondary-50 rounded-md w-full"
              }
            >
              <div class="flex flex-row">
                <div class=" flex items-center justify-center my-3.5 mx-2">
                  <img src={late ? redInfo : blueInfo} alt="" />
                </div>
                <div class=" flex-1 items-center justify-center my-2 mr-2">
                  <div class="font-inter text-Body4_MD text-Neutrals-750 tracking-tight font-medium">
                    {bodyText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "disburse":
        return (
          <div class="pb-4 space-x-4">
            <div class="bg-Neutrals-white rounded-md h-9 w-full">
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={meter} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center my-2.5 mr-2 ">
                  <div class="font-inter text-Body4_MD text-Neutrals-750 tracking-tight font-normal ">
                    <span>{bodyText}</span>
                    <span class="font-medium">
                      {limit
                        ?.toFixed(0)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                      EGP{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "ordinary":
        return (
          <div class="pb-4 space-x-4">
            <div class="bg-Secondary-50 rounded-md h-14 w-full">
              <div class="flex items-center">
                <div class=" my-4 mr-1 ml-4">
                  <p class="font-inter text-Body2_RG font-normal text-Neutrals-750 tracking-small">
                    You have
                  </p>
                </div>
                <div class="my-4">
                  <p class="font-inter text-Body2_RG font-normal text-Secondary-Secondary tracking-tight">
                    {limit
                      ?.toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </p>
                </div>
                <div class="my-4 mr-1">
                  <p class=" font-inter text-Body5_RG font-normal tracking-tight text-Secondary-Secondary">
                    EGP
                  </p>
                </div>
                <div class="my-4">
                  <p class="font-inter text-Body2_RG font-normal text-Neutrals-750 tracking-small">
                    left in your balance
                  </p>
                </div>
              </div>
            </div>
          </div>
        );

      case "repay":
        return (
          <div class="pb-4 space-x-4" dir={locale ? "ltr" : "rtl"}>
            <div class="bg-Secondary-50 rounded-md h-9 w-full">
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={blueInfo} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center my-2.5 mr-2 ">
                  <div
                    class={
                      locale
                        ? "font-inter text-Body4_RG text-Neutrals-750 tracking-tight font-normal "
                        : "font-Arabic text-Body4_RG text-Neutrals-750 tracking-tight font-normal "
                    }
                  >
                    <span>{bodyText}</span>
                    {locale ? (
                      <span class="font-semibold">
                        {Number.isInteger(limit)
                          ? Number(limit)
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          : Number(limit)
                              .toFixed(0)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        EGP{" "}
                      </span>
                    ) : (
                      <span class="font-semibold">
                        {Number.isInteger(limit)
                          ? ConvertToArabicNumbers(
                              Number(limit)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                            )
                          : ConvertToArabicNumbers(
                              Number(limit)
                                .toFixed(0)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                            )}{" "}
                        جنيه{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "Octobalance":
        return (
          <div class="pb-4 space-x-4">
            <div class="bg-Secondary-50 rounded-md h-9 w-full">
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={BlueMoney} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center my-2.5 mr-2 ">
                  {locale ? (
                    <div class="font-inter text-Body4_MD text-Neutrals-750 tracking-tight">
                      <span class="font-normal">You have </span>
                      <span class="font-semibold">
                        {limit
                          ?.toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
                        EGP
                      </span>
                      <span class="font-normal"> left in your balance</span>
                    </div>
                  ) : (
                    <div class="font-Arabic text-Body4_MD text-Neutrals-750 tracking-tight">
                      <span class="font-normal"> لديك </span>
                      <span class="font-semibold">
                        {ConvertToArabicNumbers(
                          limit
                            ?.toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        )}{" "}
                        جنيه{" "}
                      </span>
                      <span class="font-normal"> بقي في رصيدك</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      case "secondary":
        return (
          <div class="space-x-4">
            <div class={"bg-Secondary-50 rounded-md h-12 w-full"}>
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={TimeReverse} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center my-2.5 mr-2 ">
                  <div
                    class={
                      "font-inter text-Body4_MD text-Neutrals-750 tracking-tight font-normal "
                    }
                  >
                    {bodyText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "disbursefees":
        return (
          <div class="space-x-4">
            <div class={"bg-Secondary-50 rounded-md h-9 w-full"}>
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={blueInfo} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center my-2.5 mr-2 ">
                  <div
                    class={
                      "font-inter text-Body4_MD text-Neutrals-750 tracking-tight font-normal "
                    }
                  >
                    <span>{bodyText}</span>
                    <span class="font-semibold">{fees} EGP </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "disuberseSelected":
        return (
          <div class="space-x-4">
            <div class="bg-Secondary-50 rounded-md h-9 w-full">
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={blueInfo} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center ">
                  <div
                    class={
                      "font-inter text-Body4_MD text-Neutrals-750 tracking-tight font-normal my-2.5 mr-2"
                    }
                  >
                    <span>{bodyText}</span>
                    <span class="font-semibold">{fees} EGP </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case "disuberseNOT":
        return (
          <div class="space-x-4">
            <div class={"bg-Neutrals-50 rounded-md h-9 w-full"}>
              <div class="flex flex-row">
                <div class=" flex items-start justify-center my-2 mx-2">
                  <img src={GrayInfo} loading="lazy" alt="" />
                </div>
                <div class=" flex-1 items-center justify-center ">
                  <div
                    class={
                      "font-inter text-Body4_MD text-Neutrals-300 tracking-tight font-normal py-2.5 mr-2"
                    }
                  >
                    <span>{bodyText}</span>
                    <span class="font-semibold">{fees} EGP </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <div>
      {renderDisclaimer()}
      {/* <div class="pb-4 px-4">
        <div
          height={height !== "" ? height : 48}
          class={
            late
              ? "bg-Destructive-100 rounded-md w-full"
              : "bg-Secondary-50 rounded-md w-full"
          }
        >
          <div class="flex flex-row">
            <div class=" flex items-start justify-center my-3.5 mx-2">
              <img src={late ? redInfo : blueInfo} alt="" />
            </div>
            <div class=" flex-1 items-center justify-center my-2 mr-2">
              <div class="font-inter text-Body4_MD text-Neutrals-750 tracking-tight font-medium">
                {bodyText}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
OctoDisclaimer.propTypes = {
  bodyText: propTypes.string,
  late: propTypes.bool,
};
export default OctoDisclaimer;
