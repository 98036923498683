import React, { Component } from "react";
import { Checkbox } from "@chakra-ui/react";
import BankIcon from "../../assets/svg/Bank.svg";
import { locale } from "../../services/variables";
import { selectedlang } from "../../services/selectedlanguage";

function OctoRequiredDocument({ title, isRequired, isChecked, onChange }) {
  return (
    <div
      class={
        locale
          ? "flex items-center space-x-3 rounded-md bg-white shadow-octo p-4"
          : "flex flex-row-reverse items-center gap-3 rounded-md bg-white shadow-octo p-4"
      }
    >
      <img src={BankIcon} class="w-8 h-8" alt="document icon" />
      <div class={locale ? "flex-1" : "flex-1 text-right"}>
        <p class={`text-Body3_MD font-medium text-Neutrals-black ${locale ? "font-inter" : "font-Arabic"}`}>{title}</p>
        {isRequired && (
          <p class={`text-Body4_RG font-normal text-neutral-500 ${locale ? "font-inter" : "font-Arabic"}`}>
            {selectedlang.onboarding.registrationFunnel.hub3.required}
          </p>
        )}
      </div>
      <Checkbox
        onChange={onChange}
        isChecked={isChecked}
        size="lg"
        colorScheme="green"
      ></Checkbox>
    </div>
  );
}

export default OctoRequiredDocument;
