import axios from "axios";

import { OAG_API_URL } from "../services/variables";
import { logout } from "./AuthService";
const refreshToken = sessionStorage.getItem("octo-refresh");

let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  timeout: 5000,
  headers: {
    Authorization: "Bearer " + sessionStorage.getItem("octo-access"),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    console.log("INTERCEPTED ERROR", error, error.response);

    if (error.response.status === 400 && originalRequest.url === OAG_API_URL) {
      //logout();
      return Promise.reject(error);
    }
    if (error.response.status === 400) {
      //logout();
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 &&
      originalRequest.url === OAG_API_URL + "refresh/"
    ) {
      //logout();
      return Promise.reject(error);
    }

    if (
      originalRequest.url === OAG_API_URL + "user/login/" &&
      error.response.status === 401
    ) {
      return Promise.reject(error);
    }
    if (error.response.status === 404) {
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 ||
      (error.response.status === 403 &&
        error.response.status !== 404 &&
        !originalRequest._retry &&
        error.response.data.code === "token_not_valid" &&
        !error.response.data.detail)
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      let refresh = refreshToken
        ? refreshToken
        : sessionStorage.getItem("octo-refresh");
      if (refresh) {
        return new Promise(function (resolve, reject) {
          axios
            .post(OAG_API_URL + "refresh/", { refresh: refreshToken })
            .then((response) => {
              sessionStorage.setItem("octo-access", response.data.access);

              axios.defaults.headers["Authorization"] =
                "Bearer " + response.data.access;
              originalRequest.headers["Authorization"] =
                "Bearer " + response.data.access;
              processQueue(null, response.data.access);

              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }
  }
);
