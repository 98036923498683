import ActionSheet from "actionsheet-react";
import React, { forwardRef } from "react";
import HelpSvg from "../../assets/svg/Support.svg";
import OctoButton from "../OctoButton/OctoButton";
import { selectedlang } from "../../services/selectedlanguage";
import { locale } from "../../services/variables";

const NeedHelpActionSheet = forwardRef((props, ref) => {
  return (
    <ActionSheet ref={ref}>
      <div class="w-full h-full flex flex-col justify-center text-center items-center px-4 py-14">
        <img alt="" src={HelpSvg} width={"88px"} />
        <p
          class={
            locale
              ? "mt-6 text-3.5xl text-Neutrals-black font-medium font-karla"
              : "mt-6 text-3.5xl text-Neutrals-black font-medium font-Arabic"
          }
        >
          {selectedlang.onboarding.common.needHelp_actionsheet.header}
        </p>

        <p
          class={
            locale
              ? "my-2 text-base text-Neutrals-black font-normal font-inter"
              : "my-2 text-base text-Neutrals-black font-normal font-Arabic"
          }
        >
          {selectedlang.onboarding.common.needHelp_actionsheet.text}
        </p>

        <div className="w-full my-2">
          {/* <a href="tel:19991"> */}
          <a
            href="https://wa.me/201222147040"
            target="_blank"
            rel="noopener noreferrer"
          >
            <OctoButton
              variant="primary"
              buttonText={
                selectedlang.onboarding.common.needHelp_actionsheet.callButton
              }
            />
          </a>
        </div>
        {/* <div class="my-2 w-full">
          <OctoButton
            variant={"outline"}
            buttonText={
              selectedlang.onboarding.common.needHelp_actionsheet
                .leaveMessageButton
            }
          />
        </div> */}
      </div>
    </ActionSheet>
  );
});
export default NeedHelpActionSheet;
