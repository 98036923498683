import { Button, Img } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { locale } from "../../services/variables";
import bills from "../../assets/svg/Bill.svg";
import move from "../../assets/svg/Transfer.svg";
import credit from "../../assets/svg/Gaugeblue.svg";
import moveBlocked from "../../assets/svg/TransferBlocked.svg";

function OctoButton({
  onClick,
  height,
  borderRadius,
  backgroundColor,
  disabled,
  variant,
  Loading,
  buttonText,
  customColor,
  test_id,
  lineHeight,
  letterSpacing,
  px,
  py,
  pl,
  textColor,
  fontSize,
  fontWeight,
  borderColor,
  border,
  logo,
}) {
  const [Icon, setIcon] = useState(null);
  useEffect(() => {
    let selectedIcon = null;
    if (logo !== "") {
      switch (logo) {
        case "bills":
          selectedIcon = bills;
          break;

        case "move":
          selectedIcon = move;
          break;

        case "credit":
          selectedIcon = credit;
          break;
        case "moveBlocked":
          selectedIcon = moveBlocked;
          break;

        default:
          break;
      }
      setIcon(selectedIcon);
    }
  }, []);

  switch (variant) {
    case "primary":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            _disabled={{ backgroundColor: "#ECEFF1", color: "#CFD8DC" }}
            onClick={onClick}
            // borderRadius="4px"
            borderRadius={borderRadius ? borderRadius : "4px"}
            isLoading={Loading}
            height={height ? height : "48px"}
            backgroundColor="#0C264F"
            color="white"
            width="full"
            fontSize={16}
            fontWeight={600}
            isDisabled={disabled}
            px={4}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "secondary":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            _disabled={{ backgroundColor: "#ECEFF1", color: "#CFD8DC" }}
            onClick={onClick}
            borderRadius={borderRadius ? borderRadius : "4px"}
            isLoading={Loading}
            height="48px"
            border={border ? border : ""}
            borderColor={borderColor ? borderColor : ""}
            backgroundColor={backgroundColor ? backgroundColor : "#ECF5FD"}
            color={textColor ? textColor : "#137CEC"}
            width="full"
            fontSize={fontSize ? fontSize : 16}
            fontWeight={fontWeight ? fontWeight : 600}
            isDisabled={disabled}
            lineHeight={lineHeight ? lineHeight : ""}
            letterSpacing={letterSpacing ? letterSpacing : ""}
            paddingX={px ? px : ""}
            paddingY={py ? py : ""}
            paddingLeft={pl ? pl : ""}
          >
            {buttonText}
          </Button>
        </div>
      );

    case "selected":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            _disabled={{ backgroundColor: "#ECEFF1", color: "#CFD8DC" }}
            onClick={onClick}
            borderRadius={borderRadius ? borderRadius : "6px"}
            isLoading={Loading}
            height="48px"
            borderColor="#137CEC"
            borderWidth="1.5px"
            backgroundColor={backgroundColor ? backgroundColor : "#ECF5FD"}
            color={"#137CEC"}
            width="full"
            fontSize={16}
            fontWeight={400}
            isDisabled={disabled}
            lineHeight={lineHeight ? lineHeight : ""}
            letterSpacing={"-0.02em"}
            paddingX={px ? px : ""}
            paddingY={py ? py : ""}
            paddingLeft={pl ? pl : ""}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "overviewbutton":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            _disabled={{ backgroundColor: "#ECEFF1", color: "#CFD8DC" }}
            onClick={onClick}
            borderRadius={borderRadius ? borderRadius : "6px"}
            isLoading={Loading}
            height="36px"
            backgroundColor={backgroundColor ? backgroundColor : "#ECF5FD"}
            color={textColor ? textColor : "#137CEC"}
            width="full"
            fontSize={14}
            fontWeight={600}
            isDisabled={disabled}
            lineHeight={lineHeight ? lineHeight : "20px"}
            letterSpacing={letterSpacing ? letterSpacing : "-0.02em"}
            paddingX={px ? px : "3.5px"}
            paddingY={py ? py : "3px"}
            paddingLeft={pl ? pl : ""}
            marginRight="25"
            iconSpacing="3"
            leftIcon={logo ? <Img src={Icon} /> : ""}
            justifyContent="center"
          >
            {buttonText}
          </Button>
        </div>
      );
    case "outline":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            onClick={onClick}
            isLoading={Loading}
            variant="outline"
            borderRadius="4px"
            height="48px" // backgroundColor="#0C264F"
            color="#0C264F"
            borderColor="#0C264F"
            borderWidth="2px"
            width="full"
            fontSize={16}
            fontWeight={600}
            isDisabled={disabled}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "ghost":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            isLoading={Loading}
            onClick={onClick}
            variant="unstyled"
            borderRadius="4px"
            height="48px"
            color={customColor ? customColor : "#0C264F"}
            borderColor="#0C264F"
            width="full"
            fontSize={16}
            fontWeight={600}
            isDisabled={disabled}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "ghost-sm":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            isLoading={Loading}
            onClick={onClick}
            _hover={{}}
            borderradius="4px"
            size="sm"
            fontSize="14px"
            fontWeight={400}
            height="18px"
            variant="ghost"
            borderRadius="4px"
            color={customColor ? customColor : "#0C264F"}
            borderColor="#0C264F"
            width="full"
            isDisabled={disabled}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "danger":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            isLoading={Loading}
            _hover={{}}
            onClick={onClick}
            borderRadius={borderRadius ? borderRadius : "4px"}
            height="48px"
            backgroundColor="#E73648"
            color="white"
            width="full"
            fontSize={16}
            fontWeight={600}
            isDisabled={disabled}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "danger-outline":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            onClick={onClick}
            isLoading={Loading}
            variant="outline"
            borderRadius="4px"
            height="48px" // backgroundColor="#0C264F"
            color="#E73648"
            borderColor="#E73648"
            borderWidth="2px"
            width="full"
            fontSize={16}
            fontWeight={600}
            isDisabled={disabled}
          >
            {buttonText}
          </Button>
        </div>
      );
    case "danger-ghost":
      return (
        <div class={locale ? "font-arch" : "font-Arabic"}>
          <Button
            data-testid={test_id}
            _hover={{}}
            onClick={onClick}
            variant="unstyled"
            borderRadius="4px"
            height="48px"
            color="#E73648"
            width="full"
            fontSize={16}
            fontWeight={600}
            isDisabled={disabled}
            isLoading={Loading}
          >
            {buttonText}
          </Button>
        </div>
      );
    default:
      break;
  }
}
OctoButton.propTypes = {
  onclick: propTypes.func,
  disabled: propTypes.bool,
  variant: propTypes.oneOf([
    "primary",
    "secondary",
    "overviewbutton",
    "outline",
    "ghost",
    "ghost-sm",
    "danger",
    "danger-outline",
    "danger-ghost",
  ]),
  buttonText: propTypes.string,
  Loading: propTypes.bool,
};
export default OctoButton;
