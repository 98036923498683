import React from "react";
import ConvertToArabicNumbers from "../../services/utils";
import { locale } from "../../services/variables";

function FeeBadge({ fee, percentage }) {
  return (
    <div
      className={
        fee === 0
          ? "py-2 px-2 bg-Secondary-50 rounded-sm h-3 items-center justify-items-center flex"
          : "py-2 px-2 bg-Neutrals-100 rounded-sm h-3 items-center justify-items-center flex"
      }
    >
      <p
        className={`text-Body5_MD font-medium tracking-tight text-center ${
          locale ? " font-inter " : " font-Arabic "
        } ${fee === 0 ? " text-Secondary-500 " : " text-Neutrals-500 "}`}
      >
        {percentage
          ? fee !== 0
            ? locale
              ? `+${fee}% Fees`
              : `مصاريف الخدمة + ${ConvertToArabicNumbers(fee)} %`
            : locale
            ? "Free"
            : "بدون رسوم"
          : fee !== 0
          ? locale
            ? `+${fee} EGP Fees`
            : `مصاريف الخدمة + ${ConvertToArabicNumbers(fee)} ج.م`
          : locale
          ? "Free"
          : "بدون رسوم"}
      </p>
    </div>
  );
}

export default FeeBadge;
