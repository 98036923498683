import axios from "axios";
import React, { useEffect, useState } from "react";
import OctoButton from "../../../components/OctoButton/OctoButton";
import OctoInput from "../../../components/OctoInput/OctoInput";
import OctoSelect from "../../../components/OctoSelect/OctoSelect";
import { getHeader, getOctoId } from "../../../services/AuthService";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale, OAG_API_URL } from "../../../services/variables";
import { showErrorMessage } from "../../../services/utils";

function EmploymentDetails(props) {
  const [governorate, setGovernorate] = useState("");
  const [area, setArea] = useState("");
  const [governoratesList, setGovernoratesList] = useState([]);
  const [areasList, setAreasList] = useState([]);
  const [income, setIncome] = useState("");
  const [employementList, setEmployementList] = useState([]);
  const [employementType, setEmployementType] = useState("");
  const [yoe, setYOE] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const changeGovernorate = (e) => {
    getAreas(e.target.value);
    setGovernorate(
      governoratesList.find((i) => i.id === parseInt(e.target.value)).id
    );
  };

  const changeArea = (e) => {
    setArea(areasList.find((i) => i.id === parseInt(e.target.value)).id);
  };

  const changeEmployementType = (event) => {
    setEmployementType(event.target.value);
  };

  const changeYOE = (event) => {
    setYOE(event.target.value);
  };

  const changeIncome = (event) => {
    const val = event.target.value.replace(/\D/g, "");
    setIncome(val);
  };

  const getEmployementTypes = async () => {
    axios({
      method: "get",
      url: OAG_API_URL + "seeders/employmenttypes/",
      headers: await getHeader(),
      mode: "cors",
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        }
      })
      .then((responseData) => {
        setEmployementList(responseData.data);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const getGovernorates = async () => {
    axios({
      method: "get",
      url: OAG_API_URL + "seeders/governorates/",
      headers: await getHeader(),
      mode: "cors",
    })
      .then((response) => {
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setGovernoratesList(responseData.data);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const getAreas = async (id) => {
    axios({
      method: "get",
      url: OAG_API_URL + "seeders/governorates/" + id + "/areas/",
      headers: await getHeader(),
      mode: "cors",
    })
      .then((response) => {
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setAreasList(responseData.data);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const submitUserAddress = async () => {
    setLoading(true);
    console.log(governorate);
    axios({
      method: "post",
      url: OAG_API_URL + "users/" + (await getOctoId()) + "/address/",
      headers: await getHeader(),
      mode: "cors",
      data: {
        type: 0,
        governorate: governoratesList.find(
          (i) => i.id === parseInt(governorate)
        ).name_en,
        area: areasList.find((i) => i.id === parseInt(area)).name_en,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setLoading(false);
        console.log(responseData);
        sendEmploymentInfo();
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const sendEmploymentInfo = () => {
    props.onChangeIncome(income);
    props.onChangeEmploymentType(employementType);
    props.onChangeYOE(yoe);
    props.changeCurrentPage(4);
    props.navigate("questionnaire");
  };
  useEffect(() => {
    props.changeCurrentPage(3);
    getGovernorates();
    getEmployementTypes();
  }, []);

  useEffect(() => {
    const disableCheck = () => {
      if (
        income > 0 &&
        employementType !== "" &&
        yoe !== null &&
        governorate !== "" &&
        area !== ""
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    disableCheck();
  }, [income, employementType, yoe, governorate, area]);

  return (
    <div class="pt-6 lg:pt-0">
      <p
        class={
          locale
            ? "text-left text-Title2_RG tracking-wide text-Neutrals-750 font-normal font-arch mb-2"
            : "text-right text-Title2_RG tracking-wide text-Neutrals-750 font-normal font-Arabic mb-2"
        }
      >
        {
          selectedlang.onboarding.registrationFunnel
            .registration_EmploymentDetails.header
        }
      </p>
      <p
        class={
          locale
            ? "text-left text-Body2_RG tracking-small text-Neutrals-500 font-normal font-inter"
            : "text-right text-Body2_RG tracking-small text-Neutrals-500 font-normal font-Arabic"
        }
      >
        {
          selectedlang.onboarding.registrationFunnel
            .registration_EmploymentDetails.text
        }
      </p>
      <div class="mt-6">
        <OctoSelect
          onChange={changeGovernorate}
          // loadLogo={"city"}
          value={governorate}
          placeholder={
            selectedlang.onboarding.registrationFunnel
              .registration_ResidenceInfo.inputFields.governorateLabel
          }
          // data={governoratesList}
          data={governoratesList.sort(function (a, b) {
            if (locale) {
              if (a.name_en < b.name_en) {
                return -1;
              }

              if (a.name_en > b.name_en) {
                return 1;
              }
            } else {
              if (a.name_ar < b.name_ar) {
                return -1;
              }

              if (a.name_ar > b.name_ar) {
                return 1;
              }
            }

            return 0;
          })}
          optionKey={"id"}
        />
      </div>

      <div class="my-4">
        <OctoSelect
          onChange={changeArea}
          // loadLogo={"area"}
          value={area}
          placeholder={
            selectedlang.onboarding.registrationFunnel
              .registration_ResidenceInfo.inputFields.areaLabel
          }
          // data={areasList}
          data={areasList.sort(function (a, b) {
            if (locale) {
              if (a.name_en < b.name_en) {
                return -1;
              }

              if (a.name_en > b.name_en) {
                return 1;
              }
            } else {
              if (a.name_ar < b.name_ar) {
                return -1;
              }

              if (a.name_ar > b.name_ar) {
                return 1;
              }
            }

            return 0;
          })}
          optionKey={"id"}
        />
      </div>
      <div class="my-4">
        <OctoInput
          variant="specialInput"
          test_id="income-input"
          value={income}
          onChange={changeIncome}
          placeholder={
            selectedlang.onboarding.registrationFunnel
              .registration_EmploymentDetails.inputFields.income
          }
          disabled={false}
          // loadLogo={"MoneyBag"}
          // color={"#455A64"}
          moneyFlag={true}
          PY={"py-0"}
        />
      </div>

      <div class="my-4">
        <OctoSelect
          onChange={changeEmployementType}
          value={employementType}
          data={employementList}
          optionKey={"name_en"}
          placeholder={
            selectedlang.onboarding.registrationFunnel
              .registration_EmploymentDetails.inputFields.typeofemployment
          }
          // loadLogo={"TypeofEmp"}
        />
      </div>

      <div class="my-4">
        <OctoSelect
          value={yoe}
          onChange={changeYOE}
          data={[
            {
              name_en: "0",
              name_ar: "٠",
            },
            {
              name_en: "1",
              name_ar: "١",
            },
            {
              name_en: "2",
              name_ar: "٢",
            },
            {
              name_en: "3",
              name_ar: "٣",
            },
            {
              name_en: "4",
              name_ar: "٤",
            },
            {
              name_en: "5",
              name_ar: "٥",
            },
            {
              name_en: "6",
              name_ar: "٦",
            },
            {
              name_en: "7",
              name_ar: "٧",
            },
            {
              name_en: "8",
              name_ar: "٨",
            },
            {
              name_en: "9",
              name_ar: "٩",
            },
            {
              name_en: "10+",
              name_ar: "١٠+",
            },
          ]}
          optionKey={"name_en"}
          placeholder={
            selectedlang.onboarding.registrationFunnel
              .registration_EmploymentDetails.inputFields.yearsOfExpLabel
          }
          // loadLogo="time"
        />
      </div>

      <div class="mt-6">
        <OctoButton
          variant={"primary"}
          test_id={"continue-btn"}
          borderRadius="6px"
          disabled={disabled}
          onClick={() => {
            submitUserAddress();
          }}
          buttonText={
            selectedlang.onboarding.registrationFunnel
              .registration_EmploymentDetails.continueButton
          }
          Loading={loading}
        />
      </div>
    </div>
  );
}

export default EmploymentDetails;
