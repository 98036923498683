import React from "react";
import OnboardingSvg1 from "../../assets/svg/OnBoarding/Onboarding1.svg";
import OnboardingSvg2 from "../../assets/svg/OnBoarding/Onboarding2.svg";
import OnboardingSvg3 from "../../assets/svg/OnBoarding/Onboarding3.svg";
import OnboardingSvg4 from "../../assets/svg/OnBoarding/ArabicOnboarding1.svg";
import OnboardingSvg5 from "../../assets/svg/OnBoarding/ArabicOnboarding2.svg";
import OnboardingSvg6 from "../../assets/svg/OnBoarding/ArabicOnboarding3.svg";
import { selectedlang } from "../../services/selectedlanguage";
import { locale } from "../../services/variables";

function OnboardingSection() {
  return (
    <>
      <div
        class="flex-col space-y-4 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-4 text-Neutrals-black text-base"
        dir={locale ? "ltr" : "rtl"}
      >
        <div
          class={
            locale
              ? "flex w-full items-center space-x-4 bg-Neutrals-white rounded-md p-4"
              : "flex text-right w-full items-center  gap-4 rounded-md bg-Neutrals-white space-x-4 p-4"
          }
        >
          <div class="flex rounded-full items-center justify-center bg-Neutrals-surface  w-16 h-16">
            <img
              alt=""
              loading="lazy"
              src={locale ? OnboardingSvg1 : OnboardingSvg4}
              class="w-12 h-12"
            />
          </div>
          <div class={locale ? "flex-1 font-arch" : "flex-1 font-Arabic"}>
            <p class="text-Title4_RG text-Neutrals-750 tracking-tight font-normal mb-1">
              {
                selectedlang.onboarding.registrationFunnel.registration1
                  .feature1.title
              }
            </p>
            <p class="text-Body4_RG tracking-tight text-Neutrals-500 font-inter font-normal">
              {
                selectedlang.onboarding.registrationFunnel.registration1
                  .feature1.subtitle
              }
            </p>
          </div>
        </div>
        <div
          class={
            locale
              ? "flex w-full items-center  space-x-4 bg-Neutrals-white rounded-md p-4"
              : "flex text-right w-full items-center  gap-4 rounded-md bg-Neutrals-white space-x-4 p-4"
          }
        >
          <div class="flex rounded-full items-center justify-center bg-Neutrals-surface  w-16 h-16 ">
            <img
              alt=""
              loading="lazy"
              src={locale ? OnboardingSvg2 : OnboardingSvg5}
              class="w-12 h-12"
            />
          </div>
          <div class={locale ? "flex-1" : "flex-1 font-Arabic"}>
            <p class="text-Title4_RG text-Neutrals-750 tracking-tight font-arch font-normal mb-1">
              {
                selectedlang.onboarding.registrationFunnel.registration1
                  .feature2.title
              }
            </p>
            <p class="text-Body4_RG tracking-tight text-Neutrals-500 font-inter font-normal">
              {
                selectedlang.onboarding.registrationFunnel.registration1
                  .feature2.subtitle
              }
            </p>
          </div>
        </div>
        <div
          class={
            locale
              ? "flex w-full items-center  space-x-4 bg-Neutrals-white rounded-md p-4"
              : "flex text-right w-full items-center  gap-4 rounded-md bg-Neutrals-white space-x-4 p-4"
          }
        >
          <div class="flex rounded-full items-center justify-center  bg-Neutrals-surface  w-16 h-16 ">
            <img
              alt=""
              loading="lazy"
              src={locale ? OnboardingSvg3 : OnboardingSvg6}
              class="w-12 h-12"
            />
          </div>
          <div class={locale ? "flex-1" : "flex-1 font-Arabic"}>
            <p class="text-Title4_RG text-Neutrals-750 tracking-tight font-arch font-normal mb-1">
              {
                selectedlang.onboarding.registrationFunnel.registration1
                  .feature3.title
              }
            </p>
            <p class="text-Body4_RG tracking-tight text-Neutrals-500 font-inter font-normal">
              {
                selectedlang.onboarding.registrationFunnel.registration1
                  .feature3.subtitle
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default OnboardingSection;
