import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import OctoHeader from "../../components/OctoHeader/OctoHeader";
import { getHeader, getOctoId } from "../../services/AuthService";
import { selectedlang } from "../../services/selectedlanguage";
import { OAG_API_URL, locale } from "../../services/variables";
import HubAppointment from "./hubAppointment";
import HubBranch from "./hubBranch";
import HubConfirm from "./hubConfirm";
import HubOverview from "./hubOverview";
import { showErrorMessage } from "../../services/utils";
import ErrorScreen from "../error/ErrorScreen";

function HubScreen(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedBranch, setSelectedBranch] = useState({});
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [loading, setLoading] = useState(false);

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h?.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseFloat(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };
  const prevPage = () => {
    navigate(-1);
  };

  const selectBranch = (value) => {
    console.log(value);
    setSelectedBranch(value);
  };
  const selectAppointment = (value) => {
    moment.locale("en-gb");
    moment.locale("ar");
    setSelectedAppointment(value);
    localStorage.setItem("selectedAppointment", JSON.stringify(value));
  };
  const submitHubAppointment = async () => {
    setLoading(true);
    moment.locale("en-gb");
    const octoId = await getOctoId();
    let appointment_date = selectedAppointment.date;
    let appointment_time = selectedAppointment.time;
    axios({
      method: "post",
      url: OAG_API_URL + "users/" + octoId + "/hub_appointment/",
      headers: await getHeader(),
      data: {
        octoId: octoId,
        chosenDateTime:
          moment(appointment_date).format("DD-MM-YYYY") +
          " " +
          convertTime12to24(appointment_time),
        hub: selectedBranch.hubId,
      },
      mode: "cors",
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setLoading(false);
        navigate("confirm");
        console.log(responseData.data);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });

    moment.locale(locale ? "en-gb" : "ar");
  };

  return props.tokenFlag === true ? (
    <>
      <OctoHeader variant={"onboarding"} onBack={prevPage} />
      <div
        class={
          location.pathname !== "/appointment/overview/"
            ? "flex-1 lg:grid lg:grid-cols-2"
            : "flex-1 lg:grid lg:grid-cols-1"
        }
      >
        <div class={locale ? "col-span-1" : "col-span-1 order-last"}>
          <Routes>
            <Route
              path="branch"
              element={
                <HubBranch navigate={navigate} changeBranch={selectBranch} />
              }
            ></Route>
            <Route
              path="timeslot"
              element={
                <HubAppointment
                  navigate={navigate}
                  selectedBranch={selectedBranch}
                  selectAppointment={selectAppointment}
                />
              }
            ></Route>
            <Route
              path="overview"
              element={
                <HubOverview
                  navigate={navigate}
                  submitHubAppointment={submitHubAppointment}
                  selectedBranch={selectedBranch}
                  selectedAppointment={selectedAppointment}
                  loading={loading}
                />
              }
            />
            <Route
              path="confirm"
              element={
                <HubConfirm
                  navigate={navigate}
                  submitHubAppointment={submitHubAppointment}
                  selectedBranch={selectedBranch}
                  selectedAppointment={selectedAppointment}
                />
              }
            />
          </Routes>
        </div>
        <div></div>
      </div>

      <div class="mb-2 text-gray-400 text-center text-normal">
        <p>{selectedlang.copyright}</p>
      </div>
    </>
  ) : (
    <ErrorScreen></ErrorScreen>
  );
}

export default HubScreen;
