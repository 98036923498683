/*
  #Type: Utility
  ##Name: Selected Langauge
  ###Status: Active

    Quick handler for the language and selector for the json language files 
   
*/ 
import lang_en from '../en.json';
import lang_ar from '../ar.json';

const localesetting = sessionStorage.getItem("octo-set-language");

export const selectedlang = (localesetting == null ) ? lang_ar : (localesetting === "en_US") ? lang_en : lang_ar

