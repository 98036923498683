import React, { useEffect, useState } from "react";
import AddressBlock from "../../components/AddressBlock/AddressBlock";
import AppointmentBlock from "../../components/AppointmentBlock/AppointmentBlock";
import { selectedlang } from "../../services/selectedlanguage";
import OctoButton from "../../components/OctoButton/OctoButton";
import { OAG_API_URL, locale } from "../../services/variables";
import OctoRequiredDocument from "../../components/OctoRequiredDocument/OctoRequiredDocument";
import axios from "axios";
import { getHeader, getOctoId } from "../../services/AuthService";
import { showErrorMessage } from "../../services/utils";

const HubOverview = (props) => {
  const [DocListCheck, setDocListCheck] = useState([]);
  const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   const getRequiredDocuments = async () => {
  //     axios({
  //       method: "get",
  //       url:
  //         OAG_API_URL + "users/" + (await getOctoId()) + "/required_documents/",
  //       headers: await getHeader(),
  //       mode: "cors",
  //     })
  //       .then((response) => {
  //         console.log(response);

  //         if (response.status >= 200 && response.status < 300) {
  //           return response;
  //         } else {
  //         }
  //       })
  //       .then((responseData) => {
  //         let checksList = responseData.data;
  //         var i = 0,
  //           obj = null,
  //           output = [];
  //         for (i = 0; i < checksList.documents_en.length; i++) {
  //           obj = {};
  //           obj.id = i;
  //           obj.name_en = checksList.documents_en[i];
  //           obj.name_ar = checksList.documents_ar[i];
  //           obj.isRequired = true;
  //           obj.isChecked = false;

  //           output.push(obj);
  //         }
  //         setDocListCheck(output);
  //       })
  //       .catch((error) => {
  //         showErrorMessage(error);
  //       });
  //   };
  //   getRequiredDocuments();
  // }, []);
  // useEffect(() => {
  //   if (DocListCheck.length > 0 && DocListCheck.every((val) => val.isChecked)) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // }, [DocListCheck]);
  return (
    <div class="pt-6 lg:pt-0">
      <p
        class={
          locale
            ? "text-left text-Title2_RG font-normal text-Neutrals-750 font-arch tracking-small mb-2"
            : "text-right text-Title2_RG font-normal text-Neutrals-750 font-Arabic tracking-small mb-2"
        }
      >
        {selectedlang.onboarding.registrationFunnel.hub3.header}
      </p>
      <p
        class={
          locale
            ? "text-left text-Body2_RG font-normal text-Neutrals-750 font-inter tracking-small"
            : "text-right text-Body2_RG font-normal text-Neutrals-750 font-Arabic tracking-small"
        }
      >
        {selectedlang.onboarding.registrationFunnel.hub3.text}
      </p>
      <div class="my-6">
        <p
          class={
            locale
              ? "text-left text-Body2_MD font-medium text-Neutrals-800 font-inter tracking-small"
              : "text-right text-Body2_MD font-mediums text-Neutrals-800 font-Arabic tracking-small"
          }
        >
          {selectedlang.onboarding.registrationFunnel.hub3.appointment}
        </p>
        <div class="block my-4 lg:flex lg:space-x-4">
          {props.selectedBranch.address_en && (
            <AddressBlock
              clickable={false}
              address={
                locale
                  ? props.selectedBranch.address_en
                  : props.selectedBranch.address_ar
              }
              title={
                locale
                  ? props.selectedBranch.name_en
                  : props.selectedBranch.name_ar
              }
              showArrow={false}
            />
          )}
          {props.selectedAppointment.time && (
            <AppointmentBlock
              time={props.selectedAppointment.time}
              date={Date.parse(props.selectedAppointment.date)}
              showArrow={false}
              // showDate={true}
            />
          )}
        </div>
        {/* <p
          class={
            locale
              ? "text-left text-Body2_MD font-medium text-Neutrals-800 font-inter tracking-small"
              : "text-right text-Body2_MD font-mediums text-Neutrals-800 font-Arabic tracking-small"
          }
        >
          {selectedlang.onboarding.registrationFunnel.hub3.documents}
        </p> */}
        {/* <div class="space-y-4 my-4 lg:space-y-0 lg:gap-4 lg:grid lg:grid-cols-2">
          {DocListCheck.length !== 0 && (
            <>
              {DocListCheck.map((doc, index) => (
                <OctoRequiredDocument
                  title={locale ? doc.name_en : doc.name_ar}
                  isRequired={doc.isRequired}
                  isChecked={doc.isChecked}
                  onChange={() => {
                    setDocListCheck(
                      DocListCheck.map((item) =>
                        item.id === doc.id
                          ? { ...item, isChecked: !item.isChecked }
                          : item
                      )
                    );
                  }}
                />
              ))}
            </>
          )}
        </div> */}
        <div class={locale ? "flex w-full" : "flex w-full flex-row-reverse"}>
          <div class="w-full mt-6 lg:w-2/3">
            <OctoButton
              variant={"primary"}
              buttonText={
                selectedlang.onboarding.registrationFunnel.hub3.continueButton
              }
              onClick={props.submitHubAppointment}
              Loading={props.loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubOverview;
