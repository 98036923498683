import React from "react";
import { Link } from "react-router-dom";
import BackspaceSvg from "../../assets/svg/Backspace2.svg";
import { selectedlang } from "../../services/selectedlanguage";
import { locale } from "../../services/variables";

function OctoNumpad(props) {
  return (
    <>
      {locale ? (
        <div>
          <div className="my-10 grid w-full grid-cols-3 gap-x-2 gap-y-14 font-inter text-1.3xl font-medium text-Neutrals-750 tracking-tight items-center text-center justify-center justify-items-center lg:hidden">
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}1`);
              }}
            >
              <p>1</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}2`);
              }}
            >
              <p>2</p>
            </div>
            <div
              className="w-1/2"
              onClick={() => {
                props.onChange(`${props.value}3`);
              }}
            >
              <p>3</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}4`);
              }}
            >
              <p>4</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}5`);
              }}
            >
              <p>5</p>
            </div>
            <div
              className="w-1/2"
              onClick={() => {
                props.onChange(`${props.value}6`);
              }}
            >
              <p>6</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}7`);
              }}
            >
              <p>7</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}8`);
              }}
            >
              <p>8</p>
            </div>
            <div
              className="w-1/2"
              onClick={() => {
                props.onChange(`${props.value}9`);
              }}
            >
              <p>9</p>
            </div>
            {props.hasForgot ? (
              <div
                className={`font-medium tracking-tight text-Body3_MD ${
                  locale ? "font-inter" : "font-Arabic"
                } text-Neutrals-750 pr-7`}
              >
                <Link to="/forgot">
                  <p>{selectedlang.login.login.forgotButton}</p>
                </Link>
              </div>
            ) : (
              <div className="w-1/2" />
            )}

            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}0`);
              }}
            >
              <p>0</p>
            </div>
            <div
              onClick={() => {
                props.onChange(
                  props.value.substring(0, props.value.length - 1)
                );
              }}
            >
              <img alt="" loading="lazy" src={BackspaceSvg} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="my-10 grid w-full grid-cols-3 gap-x-2 gap-y-14 font-inter text-1.3xl font-medium text-Neutrals-750 tracking-tight items-center text-center justify-center justify-items-center lg:hidden">
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}1`);
              }}
            >
              <p className="font-Arabic">١</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}2`);
              }}
            >
              <p className="font-Arabic">٢</p>
            </div>
            <div
              className="w-1/2"
              onClick={() => {
                props.onChange(`${props.value}3`);
              }}
            >
              <p className="font-Arabic">٣</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}4`);
              }}
            >
              <p className="font-Arabic">٤</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}5`);
              }}
            >
              <p className="font-Arabic">٥</p>
            </div>
            <div
              className="w-1/2"
              onClick={() => {
                props.onChange(`${props.value}6`);
              }}
            >
              <p className="font-Arabic">٦</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}7`);
              }}
            >
              <p className="font-Arabic">٧</p>
            </div>
            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}8`);
              }}
            >
              <p className="font-Arabic">٨</p>
            </div>
            <div
              className="w-1/2"
              onClick={() => {
                props.onChange(`${props.value}9`);
              }}
            >
              <p className="font-Arabic">٩</p>
            </div>
            {props.hasForgot ? (
              <div
                className={`font-medium tracking-tight text-Body3_MD ${
                  locale ? "font-inter" : "font-Arabic"
                } text-Neutrals-750 pr-7`}
              >
                <Link to="/forgot">
                  <p>{selectedlang.login.login.forgotButton}</p>
                </Link>
              </div>
            ) : (
              <div className="w-1/2" />
            )}

            <div
              className="w-1/2 pr-7"
              onClick={() => {
                props.onChange(`${props.value}0`);
              }}
            >
              <p className="font-Arabic">٠</p>
            </div>
            <div
              onClick={() => {
                props.onChange(
                  props.value.substring(0, props.value.length - 1)
                );
              }}
            >
              <img alt="" loading="lazy" src={BackspaceSvg} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OctoNumpad;
