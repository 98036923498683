import React, { useEffect, useState } from "react";
import OctoButton from "../../../components/OctoButton/OctoButton";
import OnboardingSection from "../../../components/OnboardingSection/OnboardingSection";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale } from "../../../services/variables";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ErrorScreen from "../../error/ErrorScreen";
import OctoLogo from "../../../logo.svg";
import OctoLogo_ar from "../../../assets/svg/logo_ar.svg";

function InfoScreen(props) {
  const [tokenFlag, setTokenFlag] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenObj, setTokenObj] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const decodeToken = async () => {
      if (searchParams.get("token")) {
        sessionStorage.setItem("paymentToken", searchParams.get("token"));
        const decoded = jwt_decode(searchParams.get("token"));
        setTokenObj(decoded);
        props.changeTokenObj(decoded);
        setTokenFlag(true);
        props.changeTokenFlag(true);
        // sessionStorage.setItem("tokenObj", JSON.stringify(decoded));
      } else {
        setTokenFlag(false);
        props.changeTokenFlag(false);
      }
    };
    decodeToken();
  }, []);

  return tokenFlag === true ? (
    <div class="pt-6 lg:pt-0">
      <div
        class={
          locale
            ? "flex w-full lg:hidden"
            : "flex flex-row-reverse w-full lg:hidden"
        }
      >
        {locale ? (
          <img
            src={OctoLogo}
            alt="octo logo"
            width={150}
            class="block lg:hidden my-6"
          />
        ) : (
          <img
            src={OctoLogo_ar}
            alt="octo logo"
            width={150}
            class="block lg:hidden my-6"
          />
        )}
      </div>
      <div class="mb-8 space-y-4">
        <p
          class={
            locale
              ? "text-Title2_RG text-Neutrals-750 font-normal font-arch text-left tracking-small"
              : "text-Title2_RG text-Neutrals-750 font-normal font-Arabic text-right tracking-small"
          }
        >
          {selectedlang.onboarding.registrationFunnel.registration1.header}
        </p>
        <p
          class={
            locale
              ? "text-Body2_RG tracking-small text-Neutrals-400 font-normal font-inter text-left"
              : "text-Body2_RG tracking-small text-Neutrals-400 font-normal font-Arabic text-right"
          }
        >
          {selectedlang.onboarding.registrationFunnel.registration1.text}
        </p>
      </div>
      <OnboardingSection />
      <div
        class={
          locale ? "mt-8 flex w-full" : "mt-8 flex flex-row-reverse w-full"
        }
      >
        <div class="w-full lg:w-1/2 space-y-4 lg:space-y-0 lg:flex lg:space-x-4">
          {/* <div class="w-full">
            <OctoButton
              onClick={() => {
                sendAmplitudeEvent("KASHIER_ONBOARDING_LOGIN", {});
                props.navigate("/login/phone");
              }}
              buttonText={
                selectedlang.onboarding.registrationFunnel.registration1
                  .loginBtnText
              }
              disabled={false}
              borderRadius="6px"
              variant="secondary"
              px={4}
            />
          </div> */}
          <div class="w-full">
            <OctoButton
              onClick={() => {
                sendAmplitudeEvent("KASHIER_ONBOARDING_CONTINUE", {});
                props.changeCurrentPage(1);
                navigate("welcome/downpayment");
              }}
              buttonText={
                selectedlang.onboarding.registrationFunnel.registration1.btnText
              }
              disabled={false}
              borderRadius="6px"
              variant="primary"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ErrorScreen></ErrorScreen>
  );
}

export default InfoScreen;
