import React, { useEffect } from "react";
import { locale } from "../../services/variables";
import AppStoreIcon from "../../assets/svg/appstores/appstore.svg";
import GooglePlayIcon from "../../assets/svg/appstores/googleplay.svg";
import { DisburseMethod } from "../../components/DisburseMethod/DisburseMethod";
import { selectedlang } from "../../services/selectedlanguage";
import ErrorScreen from "../error/ErrorScreen";

function ConfirmScreen(props) {
  useEffect(() => {
    console.log(props);
  }, []);

  return props.tokenFlag === true ? (
    <>
      <div class="pt-6 lg:pt-0">
        <div class="mb-4 space-y-4">
          <p
            class={
              locale
                ? "text-Title2_RG text-Neutrals-750 font-normal font-arch text-left tracking-small"
                : "text-Title2_RG text-Neutrals-750 font-normal font-Arabic text-right tracking-small"
            }
          >
            {selectedlang.confirmScreen.header}
          </p>
          <p
            class={
              locale
                ? "text-Body2_RG tracking-small text-Neutrals-400 font-normal font-inter text-left"
                : "text-Body2_RG tracking-small text-Neutrals-400 font-normal font-Arabic text-right"
            }
          >
            {selectedlang.confirmScreen.text1}
          </p>
        </div>
        <div>
          {props.selectedPlan.monthlyPayment && (
            <DisburseMethod
              plan={props.selectedPlan}
              tokenObj={props.tokenObj}
              merchant={props.merchant}
            />
          )}
          <div class="flex space-x-4 w-full items-center justify-center">
            <img src={AppStoreIcon} alt="app store" width={"150px"} />
            <img src={GooglePlayIcon} alt="app store" width={"150px"} />

            {/* to do add our appid and packagename
             <a
              href="https://apps.apple.com/app/idYOUR_APP_ID"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStoreIcon} alt="App Store" width={"150px"} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=YOUR_PACKAGE_NAME"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlayIcon} alt="Google Play" width={"150px"} />
            </a> */}
          </div>
        </div>
      </div>
    </>
  ) : (
    <ErrorScreen></ErrorScreen>
  );
}

export default ConfirmScreen;
