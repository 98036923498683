import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

import OctoContainer from "./components/container";
import OctoSidebar from "./components/sidebar";
import RegisterIndexScreen from "./screens/onboarding/register/registerIndex";
import "./services/interceptor";
import LoginIndexScreen from "./screens/onboarding/login/login";
import ConfirmScreen from "./screens/confirm";

import { locale, OAG_API_URL } from "./services/variables";
import USSVG from "./assets/svg/US.svg";
import EGSVG from "./assets/svg/EG.svg";
import axios from "axios";
import ErrorScreen from "./screens/error/ErrorScreen";
import Hub from "./screens/hub/hub";
import WelcomeIndexScreen from "./screens/onboarding/welcome/welcomeIndex";
import InfoScreen from "./screens/onboarding/welcome/info";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const savedcurrentPage = parseInt(sessionStorage.getItem("currentPage"));
  console.log(savedcurrentPage);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [currentPage, setCurrentPage] = useState(
    savedcurrentPage ? savedcurrentPage : 0
  );
  const [tokenObj, setTokenObj] = useState({});
  const [merchant, setMerchant] = useState({});
  const [downpayment, setDownpayment] = useState(0);
  const [downpaymentFlag, setDownpaymentFlag] = useState(null);

  const [tokenFlag, setTokenFlag] = useState(null);
  useEffect(() => {
    const paymentToken = sessionStorage.getItem("paymentToken");
    if (paymentToken) {
      const decoded = jwt_decode(paymentToken);
      setTokenObj(decoded);
      changeTokenObj(decoded);
      setTokenFlag(true);
      changeTokenFlag(true);
    }
  }, []);
  const getMerchant = (obj) => {
    if (obj.merchantId) {
      axios({
        method: "get",
        url: OAG_API_URL + `kashier/merchant/${obj.merchantId}/`,
        mode: "cors",
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
          }
        })
        .then((responseData) => {
          sessionStorage.setItem(
            "merchantObj",
            JSON.stringify(responseData.data)
          );
          setMerchant(responseData.data);
        })
        .catch((error) => {});
    }
  };
  const changeTokenObj = (obj) => {
    setTokenObj(obj);
    getMerchant(obj);
  };
  const changeDownpayment = (payment) => {
    setDownpayment(payment);
  };
  const changeDownpaymentFlag = (flag) => {
    setDownpaymentFlag(flag);
  };
  const changeCurrentPage = (current) => {
    sessionStorage.setItem("currentPage", current);
    setCurrentPage(current);
  };
  const changeSelectedPlan = (plan) => {
    sessionStorage.setItem("selectedPlan", JSON.stringify(plan));
    setSelectedPlan(plan);
  };
  const toggleLanguage = (choice) => {
    sessionStorage.setItem("octo-set-language", choice);
    window.location.reload();
  };
  const changeTokenFlag = (flag) => {
    setTokenFlag(flag);
  };
  return (
    <OctoContainer>
      {locale ? (
        <div
          class="hidden lg:flex p-4 space-x-2 items-center w-20"
          onClick={() => toggleLanguage("ar_EG")}
        >
          <img src={EGSVG} width={"22px"} alt="عربى" />
          <p class="text-Secondary-700 text-xs">عربى</p>
        </div>
      ) : (
        <div
          class="hidden lg:flex p-4 space-x-2 items-center w-20"
          onClick={() => toggleLanguage("en_US")}
        >
          <img src={USSVG} width={"22px"} alt="english" />
          <p class="text-Secondary-700 text-xs">English</p>
        </div>
      )}
      <div class="flex flex-col w-full lg:grid lg:grid-cols-12 min-h-[640px] shadow-octo">
        <OctoSidebar currentPage={currentPage} location={location} />
        <div
          class={
            locale
              ? "p-4 lg:col-span-9 lg:p-6 bg-Neutrals-surface min-h-screen lg:min-h-0 lg:h-full w-full"
              : "p-4 lg:col-span-9 order-first lg:p-6 bg-Neutrals-surface min-h-screen lg:min-h-0 lg:h-full w-full"
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <InfoScreen
                  merchant={merchant}
                  changeTokenObj={changeTokenObj}
                  changeCurrentPage={changeCurrentPage}
                  changeTokenFlag={changeTokenFlag}
                />
              }
            ></Route>
            <Route
              path="/welcome/*"
              element={
                <WelcomeIndexScreen
                  merchant={merchant}
                  tokenObj={tokenObj}
                  tokenFlag={tokenFlag}
                  changeSelectedPlan={changeSelectedPlan}
                  changeCurrentPage={changeCurrentPage}
                  changeDownpayment={changeDownpayment}
                  changeDownpaymentFlag={changeDownpaymentFlag}
                  downpaymentFlag={downpaymentFlag}
                />
              }
            />
            <Route
              path="/*"
              element={
                <ErrorScreen
                  merchant={merchant}
                  changeTokenObj={changeTokenObj}
                  changeSelectedPlan={changeSelectedPlan}
                />
              }
            />
            <Route
              path="/register/*"
              element={
                <RegisterIndexScreen
                  merchant={merchant}
                  tokenObj={tokenObj}
                  tokenFlag={tokenFlag}
                  selectedPlan={selectedPlan}
                  changeCurrentPage={changeCurrentPage}
                />
              }
            />
            <Route
              path="/appointment/*"
              element={
                <Hub
                  merchant={merchant}
                  tokenObj={tokenObj}
                  tokenFlag={tokenFlag}
                  selectedPlan={selectedPlan}
                  changeCurrentPage={changeCurrentPage}
                />
              }
            />
            <Route
              path="/login/*"
              element={
                <LoginIndexScreen
                  merchant={merchant}
                  tokenObj={tokenObj}
                  tokenFlag={tokenFlag}
                  selectedPlan={selectedPlan}
                  downpayment={downpayment}
                  changeCurrentPage={changeCurrentPage}
                />
              }
            />
            <Route
              path="/confirm"
              element={
                <ConfirmScreen
                  merchant={merchant}
                  selectedPlan={selectedPlan}
                  tokenObj={tokenObj}
                  tokenFlag={tokenFlag}
                  changeCurrentPage={changeCurrentPage}
                />
              }
            />
          </Routes>
          {locale ? (
            <div
              class="flex lg:hidden pt-8 space-x-2 items-center justify-center"
              onClick={() => toggleLanguage("ar_EG")}
            >
              <img src={EGSVG} width={"22px"} alt="عربى" />
              <p class="text-Secondary-700 text-xs">عربى</p>
            </div>
          ) : (
            <div
              class="flex lg:hidden pt-8 space-x-2 items-center justify-center"
              onClick={() => toggleLanguage("en_US")}
            >
              <img src={USSVG} width={"22px"} alt="english" />
              <p class="text-Secondary-700 text-xs">English</p>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        style={{
          paddingBottom: 32,
          paddingRight: 16,
          paddingLeft: 16,
          borderRadius: 4,
        }}
      />
    </OctoContainer>
  );
}

export default App;
