const PROTOCOL = "https://";
let environment = "";
switch (process.env.REACT_APP_NODE_ENV) {
  case "dev":
    environment = "dev";
    break;
  case "staging":
    environment = "staging";

    break;
  case "prod":
    environment = "";
    break;
  default:
    break;
}
//if environment is not production , add the respective backend environment to the OAG service
const OAG_SERVICE = environment !== "" ? `oag.${environment}` : "oag";

const DOMAIN = "getocto.io";

export const OAG_API_URL = PROTOCOL + OAG_SERVICE + "." + DOMAIN + "/api/";
export const headers = {
  Authorization: "Bearer " + sessionStorage.getItem("octo-access"),
  // "Content-Type": "application/json",
  // "accept": "application/json",
  // "Access": "Any"
};

let localesetting = sessionStorage.getItem("octo-set-language");
export const locale = localesetting === "en_US";

export const TCLINK = "https://prod.d15rgxrlawwfu7.amplifyapp.com/toc2.pdf";
export const TCLINK_DISBURSE =
  "https://prod.d15rgxrlawwfu7.amplifyapp.com/toc.pdf";
