import moment from "moment";
import React from "react";
import OctoDisclaimer from "../OctoDisclaimer/OctoDisclaimer";
import { locale } from "../../services/variables";
import ConvertToArabicNumbers from "../../services/utils";
import calendarIcon from "../../assets/svg/Calendar.svg";
import calendarMonthlyIcon from "../../assets/svg/CalendarSyncDisburse.svg";
import coinsIcon from "../../assets/svg/CoinsDisburse.svg";
import EasyPlan from "../../assets/svg/PaperBoat.svg";
import ShoppingSVG from "../../assets/svg/ShoppingCart.svg";

export function DisburseMethod({ plan, tokenObj, merchant }) {
  return (
    <>
      <div className="px-4 py-4 my-4 bg-white rounded-md border-Neutrals-200 shadow-octo">
        <div className="flex items-center justify-between">
          <div className="w-full">
            <div
              className={`flex ${
                locale ? "flex-row" : "flex-row-reverse space-x-reverse"
              } justify-start space-x-3 items-center pb-4`}
            >
              <img
                alt=""
                loading="lazy"
                src={merchant.icon ? merchant.icon : ShoppingSVG}
                width={48}
              />
              <div className="flex flex-col">
                <p
                  className={`text-Body1_B text-Neutrals-750 font-semibold ${
                    locale ? "font-inter" : "font-Arabic text-right"
                  } tracking-tight`}
                >
                  {locale ? merchant.name_en : merchant.name_ar}
                </p>

                <p
                  className={`text-Body3_RG text-Neutrals-750 font-normal ${
                    locale ? "font-inter" : "font-Arabic text-right"
                  } tracking-tight`}
                >
                  {locale
                    ? tokenObj.amount + " EGP" + " • "
                    : ConvertToArabicNumbers(tokenObj.amount) + " جنيه" + " • "}
                  {plan.numberOfInstallments > 1
                    ? locale
                      ? `${plan.numberOfInstallments}-months plan`
                      : `خطة ${ConvertToArabicNumbers(
                          plan.numberOfInstallments
                        )} أشهر`
                    : locale
                    ? `${plan.numberOfInstallments}-month plan`
                    : `خطة شهر واحد`}
                </p>
              </div>
            </div>
            <div className="h-px bg-neutral-200"></div>
          </div>
        </div>

        <div className="w-full bg-Neutrals-100" />

        <div className="pt-4">
          <div
            className={`flex ${
              locale ? "flex-row" : "flex-row-reverse"
            } justify-evenly  w-full`}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center">
                <img src={coinsIcon} width={20} height={20} alt="" />
              </div>

              <>
                <p className="flex items-center pt-2 pb-0.5 font-inter text-Body4_RG text-center tracking-tight text-Neutrals-450 font-normal not-italic">
                  {locale ? "Total" : "إجمالي"}
                </p>
                <p className="flex items-center not-italic font-medium tracking-tight text-center text-Body3_MD text-Neutrals-500 font-inter">
                  {locale
                    ? plan.creditWithFees.toFixed(2) + " EGP"
                    : ConvertToArabicNumbers(plan.creditWithFees.toFixed(2)) +
                      " جنيه"}
                </p>
              </>
            </div>
            {/* Until section */}
            <div className="flex flex-col items-center">
              <div className="flex items-center">
                <img src={calendarIcon} width={20} height={20} alt="" />
              </div>

              <>
                {" "}
                <p className="flex items-center pt-2 pb-0.5 font-inter text-Body4_RG text-center tracking-tight text-Neutrals-450 font-normal not-italic">
                  {locale ? "Until" : "حتى"}
                </p>
                <p className="flex items-center not-italic font-medium tracking-tight text-center text-Body3_MD text-Neutrals-500 font-inter">
                  {moment(plan.lastPayment, "YYYY-MM-DD").format("DD MMM YYYY")}
                </p>
              </>
            </div>

            <div className="flex flex-col items-center">
              <>
                {" "}
                <div className="flex items-center">
                  <img
                    src={calendarMonthlyIcon}
                    width={20}
                    height={20}
                    alt=""
                  />
                </div>
                <p className="flex items-center pt-2 pb-0.5 font-inter text-Body4_RG text-center tracking-tight text-Neutrals-450 font-normal not-italic">
                  {locale ? "Monthly" : "شهري"}
                </p>
                <p className="flex items-center not-italic font-medium tracking-tight text-center text-Body3_MD text-Neutrals-500 font-inter">
                  {locale
                    ? plan.monthlyPayment.toFixed(2) + " EGP"
                    : ConvertToArabicNumbers(plan.monthlyPayment.toFixed(2)) +
                      " جنيه"}
                </p>
              </>
            </div>
          </div>
          <div className="pt-4">
            <OctoDisclaimer
              variant="repay"
              bodyText={locale ? "Fees cost " : "مصاريف الطلب "}
              limit={plan.fees}
            />
          </div>
        </div>
      </div>
    </>
  );
}
