import axios from "axios";
import React, { useEffect, useState } from "react";
import EmptyCalendarSvg from "../../assets/svg/Empty_Calendar.svg";
import AppointmentBlock from "../../components/AppointmentBlock/AppointmentBlock";
import { selectedlang } from "../../services/selectedlanguage";
import { locale, OAG_API_URL } from "../../services/variables";
import { getHeader } from "../../services/AuthService";
import OctoDateInput from "../../components/OctoDateInput/OctoDateInput";
import { showErrorMessage } from "../../services/utils";

const startDate = new Date();
const HubAppointment = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [day, setDay] = useState(startDate);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [workingHours, setWorkingHours] = useState(null);
  const [weekdays, setWeekdays] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    getBranchDetails();
  }, []);
  useEffect(() => {
    console.log(selectedDate);
    if (
      selectedDate &&
      workingHours &&
      !workingHours.weekdays.includes(selectedDate.getDay())
    ) {
      setAppointmentSlots([]);
    } else {
      if (selectedDate) {
        generateTimeslots();
      }
    }
  }, [selectedDate, workingHours, weekdays]);

  const generateTimeslots = () => {
    if (workingHours) {
      var startTime =
        selectedDate?.getDate() === day.getDate()
          ? day.getHours() > parseFloat(workingHours.from_hour.split(":")[0])
            ? day.getHours()
            : parseFloat(workingHours.from_hour.split(":")[0])
          : parseFloat(workingHours.from_hour.split(":")[0]);
      var ampm = "";
      var arr = [],
        i,
        j;

      for (
        i = startTime;
        i < parseFloat(workingHours.to_hour.split(":")[0]);
        i++
      ) {
        if (i >= 12) {
          ampm = "PM";
        } else {
          ampm = "AM";
        }

        for (j = 0; j < 3; j++) {
          arr.push(
            (i < 10 || i > 12 ? "0" : "") +
              (i % 12 || 12) +
              ":" +
              (j === 0 ? "00" : 20 * j) +
              " " +
              ampm
          );
        }
      }
      setAppointmentSlots(arr);
      setLoading(false);
    }
  };
  const getBranchDetails = async () => {
    if (props.selectedBranch) {
      axios({
        method: "get",
        url: OAG_API_URL + "hub/" + props.selectedBranch.hubId + "/",
        headers: await getHeader(),
        mode: "cors",
      })
        .then((response) => {
          console.log(response);

          if (response.status >= 200 && response.status < 300) {
            return response;
          }
        })
        .then((responseData) => {
          localStorage.setItem(
            "selectedBranch",
            JSON.stringify(responseData.data)
          );
          setWeekdays(responseData.data.workingHours.weekdays);
          setWorkingHours(responseData.data.workingHours);
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    }
  };
  const onChangeDate = (date) => {
    setSelectedDate(date);

    if (date) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  };
  return (
    <div class="pt-6 lg:pt-0">
      <p
        class={
          locale
            ? "text-left text-Title2_RG font-normal text-Neutrals-750 tracking-small font-arch mb-2"
            : "text-right text-Title2_RG font-normal text-Neutrals-750 tracking-small font-Arabic mb-2"
        }
      >
        {selectedlang.onboarding.registrationFunnel.hub2.header}
      </p>
      <p
        class={
          locale
            ? "text-left text-Body2_RG tracking-small font-normal text-Neutrals-750 font-inter"
            : "text-right text-Body2_RG tracking-small font-normal text-Neutrals-750 font-Arabic"
        }
      >
        {selectedlang.onboarding.registrationFunnel.hub2.text}
      </p>
      <div class="my-6">
        <div class="text-normal mb-6">
          <OctoDateInput
            variant={"primary"}
            loadLogo="BlackCalender"
            onChange={onChangeDate}
            selected={selectedDate}
            minDate={new Date()}
            maxDate={new Date(new Date().getFullYear() + 1, 0, 1)}
            placeholder={locale ? "Select date" : "اختار اليوم"}
            isSelect={isSelect}
            // rowReverse={!locale}
          />
        </div>
        {Loading ? (
          ""
        ) : appointmentSlots.length > 0 ? (
          <div class="md:overflow-y-scroll md:h-80">
            {appointmentSlots.map((slot, index) => (
              <div
                class="my-4"
                onClick={() => {
                  props.selectAppointment({
                    time: slot,
                    date: selectedDate,
                  });
                  props.navigate("overview/");
                }}
              >
                <AppointmentBlock
                  time={slot}
                  // date={selectedDate ? Date.parse(selectedDate) : ""}
                  showArrow={true}
                  // showDate={false}
                />
              </div>
            ))}
          </div>
        ) : (
          <div class="flex flex-col items-center justify-center my-9 text-center">
            <img alt="" loading="lazy" src={EmptyCalendarSvg} />
            <p
              class={
                locale
                  ? "mt-4 font-normal text-Title2_RG font-arch tracking-wide text-center text-Neutrals-750"
                  : "mt-4 font-normal text-Title2_RG font-Arabic tracking-wide text-center text-Neutrals-750"
              }
            >
              {selectedlang.onboarding.registrationFunnel.hub2.noResultTitle}
            </p>
            <p
              class={
                locale
                  ? "font-normal text-Body2_RG tracking-tight text-center text-Neutrals-750 font-inter mt-2"
                  : "font-normal text-Body2_RG tracking-tight text-center text-Neutrals-750 font-Arabic mt-2"
              }
            >
              {selectedlang.onboarding.registrationFunnel.hub2.noResultSubtitle}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HubAppointment;
