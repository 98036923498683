import axios from "axios";
import jwt_decode from "jwt-decode";

const token = sessionStorage.getItem("octo-access");
const refresh = sessionStorage.getItem("octo-refresh");
const registerStep = sessionStorage.getItem("registerStep");
const language = sessionStorage.getItem("octo-set-language");

export function isLoggedIn(props) {
  if (
    token &&
    registerStep === null &&
    (props.history.location.pathname !== "/welcome" ||
      props.history.location.pathname !== "/register" ||
      props.history.location.pathname !== "/login" ||
      props.history.location.pathname !== "/delivery" ||
      props.history.location.pathname !== "/hub" ||
      props.history.location.pathname !== "/confirmation")
  ) {
    //console.log("Logged in")
    return true;
  } else {
    return false;
  }
  //return true;
}
export async function getHeader() {
  const access = await sessionStorage.getItem("octo-access");
  const headers = {
    Authorization: "Bearer " + access,
    "Content-Type": "application/json",

  };
  return headers;
}
export async function getRefreshToken() {
  const refresh = await sessionStorage.getItem("octo-refresh");
  return refresh;
}
export async function getOctoId() {
  const access = await sessionStorage.getItem("octo-access");
  if (access) {
    const decoded = jwt_decode(access);
    return decoded.user_octoId;
  }
}
export function logout(props) {
  //   const language = sessionStorage.getItem("octo-set-language");
  // sessionStorage.clear()
  //  sessionStorage.setItem("octo-set-language",language)
  sessionStorage.setItem("octo-set-session-expired", true);

  window.location.reload();
}
