import React, { useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import { locale } from "../../services/variables";
// import "react-datepicker/dist/react-datepicker.css";
import "../../Styles/datepicker.css";
import Calendar from "../../assets/svg/BirthdateCal.svg";
import BlackCalender from "../../assets/svg/BlackCalender.svg";
import ar from "date-fns/locale/ar-EG";

function OctoDateInput({
  variant,
  loadLogo,
  onChange,
  rowReverse,
  selected,
  value,
  placeholder,
  isSelect,
  iconPadding,
  minDate,
  maxDate,
}) {
  useEffect(() => {
    if (locale !== "en_US") {
      registerLocale("ar", ar);
    }
  }, []);

  let selectedIcon = null;

  switch (loadLogo) {
    case "Calendar":
      selectedIcon = Calendar;
      break;
    case "BlackCalender":
      selectedIcon = BlackCalender;
      break;
    default:
      break;
  }
  const renderInput = () => {
    switch (variant) {
      case "primary":
        return (
          <div>
            <div
              dir={locale ? "ltr" : "rtl"}
              className={`bg-white flex justify-start items-center p-4 ${
                locale ? "space-x-3" : "gap-3"
              } rounded-md shadow-octo`}
            >
              <img
                src={selectedIcon}
                alt=""
                loading="lazy"
                width={"28px"}
                height={"28px"}
                class=""
              />
              <div class="relative z-0 w-full h-8">
                <DatePicker
                  disabledKeyboardNavigation={true}
                  selected={selected ? selected : ""}
                  scrollableYearDropdown={true}
                  showYearDropdown={true}
                  onChange={onChange}
                  dateFormat={`${rowReverse ? "eeee" : ""} ${
                    locale ? "dd MMM. yyyy" : "dd MMMM yyyy"
                  }`}
                  portalId="calendar-portal"
                  locale={locale ? "" : "ar"}
                  showMonthDropdown={true}
                  yearDropdownItemNumber={100}
                  minDate={minDate}
                  maxDate={maxDate}
                  className={`border-none pr-0 pl-0 ring-0 bg-transparent w-full`}
                  // onSelect={{
                  //   selected: { value },
                  // }}
                  onFocus={(e) => e.target.blur()}
                />
                {isSelect ? (
                  <label
                    dir={locale ? "ltr" : "rtl"}
                    className={`${
                      locale
                        ? "font-inter left-0"
                        : "text-right font-Arabic -right-3"
                    } absolute text-Body3_MD text-neutral-500 font-normal duration-300 transform -translate-y-3 scale-75 top-1 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 z-10`}
                  >
                    {placeholder}
                  </label>
                ) : (
                  <label
                    dir={locale ? "ltr" : "rtl"}
                    className={`${
                      locale
                        ? "font-inter left-0"
                        : "text-right font-Arabic right-0 "
                    } absolute text-Body3_MD text-neutral-500 font-normal  duration-300 transform -translate-y-3 top-5 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 -z-10`}
                  >
                    {placeholder}
                  </label>
                )}
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div class={locale ? "font-inter w-full" : "font-Arabic w-full"}>
      {renderInput()}
    </div>
  );
}
export default OctoDateInput;
