import { Checkbox } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OctoButton from "../../../components/OctoButton/OctoButton";
import OctoDateInput from "../../../components/OctoDateInput/OctoDateInput";
import OctoInput from "../../../components/OctoInput/OctoInput";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { selectedlang } from "../../../services/selectedlanguage";
import {
  locale,
  OAG_API_URL,
  TCLINK_DISBURSE,
} from "../../../services/variables";
import { showErrorMessage } from "../../../services/utils";

function RegisterPhone(props) {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [tocCheck, setTOC] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const checkRegisteration = () => {
    setLoading(true);
    props.setPhone(phone);
    props.setFirstName(name.split(" ")[0]);
    props.setBirthDate(date);
    props.setLastName(name.split(" ")[name.split(" ").length - 1]);
    props.setEmail(email);
    axios({
      method: "post",
      url: OAG_API_URL + "verification/sms/request/",
      mode: "cors",
      data: {
        phoneNumber: "+2" + phone,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("fname", name.split(" ")[0]);
        sessionStorage.setItem(
          "lname",
          name.split(" ")[name.split(" ").length - 1]
        );

        toast.success(
          locale
            ? "Your OTP will be sent to you shortly"
            : "سيتم إرسال رمز التحقق إليك خلال لحظات قليلة",
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            rtl: !locale,
          }
        );
      })
      .then(() => {
        setLoading(false);
        props.changeCurrentPage(1);
        props.navigate("otp");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        showErrorMessage(error);
        return false;
      });
  };
  const changePhone = (event) => {
    // setPhone(event.target.value);
    const myValue = event.target.value;
    setPhone(myValue.replace(/\s/g, ""));
  };
  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changeName = (event) => {
    setName(event.target.value);
  };
  const checkedTOC = (event) => {
    setTOC(event.target.checked);
  };

  const handleChange = (date) => {
    setDate(date);
    if (date) {
      setIsDateSelected(true);
    } else {
      setIsDateSelected(false);
    }
  };
  useEffect(() => {
    props.changeCurrentPage(0);
  }, []);
  useEffect(() => {
    const disableCheck = () => {
      if (
        name !== "" &&
        name.match(/[^ ]+/g).length > 1 &&
        name.match(/[^ ]+/g)[0].length > 2 &&
        name.match(/[^ ]+/g)[name.match(/[^ ]+/g).length - 1].length > 2 &&
        phone !== "" &&
        phone.length === 11 &&
        phone.match(/^\d+$/) !== null &&
        date !== "" &&
        tocCheck &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    disableCheck();
  }, [phone, name, email, tocCheck, date]);

  return (
    <div class="pt-6 lg:pt-0">
      <p
        class={
          locale
            ? "text-left text-Title2_RG text-Neutrals-750 font-normal font-arch tracking-wide mb-2"
            : "text-right text-Title2_RG text-Neutrals-750 font-normal font-Arabic tracking-wide mb-2"
        }
      >
        {selectedlang.onboarding.registrationFunnel.registration2.header}
      </p>
      <p
        class={
          locale
            ? "text-left text-Body2_RG text-Neutrals-500 font-normal font-inter tracking-small"
            : "text-right text-Body2_RG text-Neutrals-500 font-normal font-Arabic tracking-small"
        }
      >
        {selectedlang.onboarding.registrationFunnel.registration2.text}
      </p>
      <div class="my-6">
        <div class="my-4 font-inter">
          <OctoInput
            variant="specialInput"
            test_id="name-input"
            value={name}
            onChange={changeName}
            onClick={() => {
              sendAmplitudeEvent("KASHIER_INIT_NAME");
            }}
            criteria={
              name !== "" &&
              name.match(/[^ ]+/g).length > 1 &&
              name.match(/[^ ]+/g)[0].length > 2 &&
              name.match(/[^ ]+/g)[name.match(/[^ ]+/g).length - 1].length > 2
            }
            placeholder={
              selectedlang.onboarding.registrationFunnel.registration2
                .inputFields.placeholderName
            }
            disabled={false}
            loadLogo={"Profile"}
            type="text"
            iconPadding="p-0"
          />
        </div>
        <div class="my-4 font-inter">
          <OctoInput
            variant="specialInput"
            test_id="phone-input"
            value={phone}
            maxnumber={11}
            onChange={changePhone}
            onClick={() => {
              sendAmplitudeEvent("KASHIER_INIT_PHONE");
            }}
            criteria={
              phone !== "" &&
              phone.length === 11 &&
              phone.match(/^\d+$/) !== null
            }
            placeholder={
              selectedlang.onboarding.registrationFunnel.registration2
                .inputFields.placeholderPhone
            }
            disabled={false}
            loadLogo={"Mobile"}
            type="tel"
          />
        </div>
        <div class="my-4 font-inter">
          <OctoInput
            variant="specialInput"
            test_id="email-input"
            value={email}
            onChange={changeEmail}
            onClick={() => {
              sendAmplitudeEvent("KASHIER_INIT_EMAIL");
            }}
            criteria={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              email
            )}
            placeholder={
              selectedlang.onboarding.registrationFunnel.registration2
                .inputFields.placeholderEmail
            }
            disabled={false}
            loadLogo={"Email"}
            type="email"
            iconPadding="p-0"
          />
        </div>
      </div>
      <div class="my-6">
        <OctoDateInput
          variant="primary"
          loadLogo="Calendar"
          onChange={handleChange}
          selected={date}
          minDate={new Date(new Date().getFullYear() - 65, 0, 1)}
          maxDate={new Date(new Date().getFullYear() - 16, 0, 1)}
          placeholder={
            selectedlang.onboarding.registrationFunnel.registration2.inputFields
              .placeholderBirthDate
          }
          isSelect={isDateSelected}
        />
      </div>
      <div
        className={`flex  items-center my-4 ${
          locale
            ? " font-inter space-x-2 "
            : " font-Arabic flex-row-reverse gap-x-2"
        } `}
      >
        <Checkbox
          onChange={checkedTOC}
          isChecked={tocCheck}
          size="lg"
          colorScheme="green"
        ></Checkbox>
        <p class="text-Body3_RG text-Neutrals-750 tracking-small font-normal">
          {selectedlang.onboarding.registrationFunnel.registration2.terms1}
          <span
            onClick={() => window.open(TCLINK_DISBURSE)}
            class="text-Secondary-500 font-medium text-Body3_RG tracking-small"
          >
            {selectedlang.onboarding.registrationFunnel.registration2.terms2}
          </span>
        </p>
      </div>
      <div class={locale ? "flex w-full" : "flex w-full flex-row-reverse"}>
        <div class="w-full mt-6 lg:w-2/3">
          <OctoButton
            variant="primary"
            test_id={"continue-btn"}
            onClick={() => {
              sendAmplitudeEvent("KASHIER_INIT_CONTINUE");
              checkRegisteration();
            }}
            buttonText={
              selectedlang.onboarding.registrationFunnel.registration2
                .continueButton
            }
            disabled={disabled}
            Loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default RegisterPhone;
