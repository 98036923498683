import axios from "axios";
import React, { useEffect, useState } from "react";
import OctoButton from "../../../components/OctoButton/OctoButton";
import OctoMCQ from "../../../components/OctoMCQ/OctoMCQ";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale, OAG_API_URL } from "../../../services/variables";
import { getHeader, getOctoId } from "../../../services/AuthService";
import { showErrorMessage } from "../../../services/utils";

function OtherDetails(props) {
  //LIST STATES
  const [questions, setQuestions] = useState([]);
  ///SELECTION STATES
  const [answers, setAnswers] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getQuestionare = async () => {
      axios({
        method: "get",
        url: OAG_API_URL + "seeders/financial_status_questions",
        headers: await getHeader(),
        mode: "cors",
      })
        .then((response) => {
          console.log(response);

          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
          }
        })
        .then((responseData) => {
          let questions = responseData.data;
          questions.forEach((element) => {
            element.answer = null;
          });
          console.log(questions);
          setQuestions(responseData.data);
          setAnswers(questions);
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    };
    getQuestionare();
    props.changeCurrentPage(4);
  }, []);
  useEffect(() => {
    if (answers.every((val) => val.answer !== null) && questions.length !== 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [answers]);

  const sumbitQuestionare = async () => {
    setLoading(true);
    const octoId = await getOctoId();
    axios({
      method: "post",
      url: OAG_API_URL + "users/" + octoId + "/finances/",
      mode: "cors",
      headers: await getHeader(),
      data: {
        initialData: answers,
        monthlyIncome: props.income,
        employmentType: props.employementType,
        yearsOfEmploymentAtLocation: props.yoe,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setLoading(false);
        sendAmplitudeEvent("KASHIER_BIRTHDATE_SUCCESS", {
          octo_id: octoId,
        });
        console.log(responseData);
        props.changeCurrentPage(5);
        props.navigate("credit");
      })
      .catch((error) => {
        setLoading(false);
        sendAmplitudeEvent("KASHIER_IRTHDATE_FAIL", {
          octo_id: octoId,
        });
        showErrorMessage(error);
      });
  };
  return (
    <div class="pt-6 lg:pt-0">
      <p
        class={
          locale
            ? "text-left text-Title2_RG tracking-wide text-Neutrals-750 font-normal font-arch mb-2"
            : "text-right text-2.5xl text-Neutrals-black font-normal font-Arabic mb-2"
        }
      >
        {
          selectedlang.onboarding.registrationFunnel.registration_OtherDetails
            .header
        }
      </p>
      <p
        class={
          locale
            ? "text-left text-Body2_RG tracking-small text-Neutrals-500 font-normal font-inter"
            : "text-right text-Body2_RG tracking-small text-Neutrals-500 font-normal font-Arabic"
        }
      >
        {
          selectedlang.onboarding.registrationFunnel.registration_OtherDetails
            .text
        }
      </p>

      {answers.length !== 0 ? (
        <>
          {answers.map((question, index) => (
            <>
              <p
                class={
                  locale
                    ? "text-left text-Body3_MD tracking-tight text-Neutrals-750 font-medium font-inter mt-6"
                    : "text-right text-Body3_MD tracking-tight text-Neutrals-750 font-medium font-Arabic mt-6"
                }
              >
                {locale ? question.question_en : question.question_ar}
              </p>
              <div class="flex flex-row  mt-3  w-full space-x-4">
                <div class="flex-1 ">
                  <OctoMCQ
                    variant="primary"
                    option={
                      question.answer === false ? "selected" : "secondary"
                    }
                    isSelected={question.answer === false}
                    id={question.id}
                    buttonText={
                      selectedlang.onboarding.registrationFunnel
                        .registration_OtherDetails.inputFields.NO
                    }
                    onClick={() => {
                      setAnswers(
                        answers.map((item) =>
                          item.id === question.id
                            ? { ...item, answer: false }
                            : item
                        )
                      );
                    }}
                  />
                </div>
                <div class="flex-1 ">
                  <OctoMCQ
                    variant="primary"
                    option={question.answer === true ? "selected" : "secondary"}
                    isSelected={question.answer === true}
                    id={question.id}
                    buttonText={
                      selectedlang.onboarding.registrationFunnel
                        .registration_OtherDetails.inputFields.YES
                    }
                    onClick={() => {
                      setAnswers(
                        answers.map((item) =>
                          item.id === question.id
                            ? { ...item, answer: true }
                            : item
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <></>
      )}
      <div class={locale ? "flex w-full" : "flex w-full flex-row-reverse"}>
        <div class="w-full mt-6 lg:w-2/3">
          <OctoButton
            variant={"primary"}
            test_id={"continue-btn"}
            borderRadius="6px"
            disabled={disabled}
            onClick={() => {
              sendAmplitudeEvent("KASHIER_OTHER_DETAILS");
              sumbitQuestionare();
            }}
            buttonText={
              selectedlang.onboarding.registrationFunnel
                .registration_OtherDetails.continueButton
            }
            Loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default OtherDetails;
