import axios from "axios";
import React, { useEffect, useState } from "react";
import { selectedlang } from "../../../services/selectedlanguage";
import { OAG_API_URL, locale } from "../../../services/variables";
import ConfettiSvg from "../../../assets/svg/Confetti.svg";
import { getHeader, getOctoId } from "../../../services/AuthService";
import OctoButton from "../../../components/OctoButton/OctoButton";
import { showErrorMessage } from "../../../services/utils";

function RegisterOverview(props) {
  const [creditLine, setCreditLine] = useState(null);

  const getCreditLine = async () => {
    const octoId = await getOctoId();
    // axios({
    //   method: "get",
    //   url: `${OAG_API_URL}credit/${octoId}/credit_estimate/`,
    //   headers: await getHeader(),
    //   mode: "cors",
    // })
    //   .then((response) => {
    //     if (response.status >= 200 && response.status < 300) {
    //       return response;
    //     }
    //   })
    //   .then((responseData) => {
    //     setCreditLine(responseData.data.creditLine);
    //   })
    //   .catch((error) => {});

    axios({
      method: "get",
      url: `${OAG_API_URL}credit/${octoId}/refresh_credit_line/`,
      headers: await getHeader(),
      mode: "cors",
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        }
      })
      .then((responseData) => {
        setCreditLine(responseData.data.creditLine);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  useEffect(() => {
    props.changeCurrentPage(5);
    getCreditLine();
  }, []);

  return (
    <div className="pt-8">
      <div className="text-center">
        <div className="flex items-center justify-center mb-5">
          <img alt="congrats" src={ConfettiSvg} width={88} height={88} />
        </div>
        <p
          className={`text-Title2_RG text-Neutrals-750 font-normal mb-2 text-center tracking-wide ${
            locale ? " font-arch " : " font-Arabic "
          } `}
        >
          {selectedlang.onboarding.registrationFunnel.registration9.text1}
        </p>
        {creditLine ? (
          <p
            className={`text-Numbers2_RG tracking-small text-center text-Secondary-500 font-medium mb-4 ${
              locale ? " font-arch " : " font-Arabic "
            } `}
          >
            {locale
              ? `${creditLine} EGP`
              : creditLine?.toLocaleString("ar-EG") +
                selectedlang.onboarding.registrationFunnel.registration9.egp}
          </p>
        ) : (
          <div
            role="status"
            className="w-full mb-4 space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
          >
            <div className="flex items-center justify-center h-16 m-auto bg-gray-300 rounded w-52 sm:w-96" />
          </div>
        )}
        <p
          className={`text-Body2_RG text-Neutrals-750 font-normal text-center tracking-small ${
            locale ? " font-inter " : " font-Arabic "
          } `}
        >
          {selectedlang.onboarding.registrationFunnel.registration9.text2}
          <br />
          <span className="font-semibold">
            {selectedlang.onboarding.registrationFunnel.registration9.text3}
          </span>
        </p>
      </div>
      <div className="my-6">
        <div>
          <OctoButton
            variant="primary"
            test_id="continue-btn"
            borderRadius="6px"
            disabled={false}
            onClick={() => {
              props.changeCurrentPage();
              props.navigate("/appointment/branch/");
            }}
            buttonText={
              selectedlang.onboarding.registrationFunnel.registration9
                .continueButton
            }
          />
        </div>
      </div>
    </div>
  );
}

export default RegisterOverview;
