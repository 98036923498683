import React, { createRef } from "react";
import { locale } from "../../services/variables";
import ArrowLeftSvg from "../../assets/svg/LightArrowLeft.svg";
import ArrowRightSvg from "../../assets/svg/ArrowRight.svg";
import { selectedlang } from "../../services/selectedlanguage";
import HelpSvg from "../../assets/svg/Help.svg";
import MerchantIcon from "../../assets/images/Amideast.png";
import NeedHelpActionSheet from "../NeedHelpActionSheet/NeedHelpActionSheet";

const progressBarHub = [
  "transition-all ease-out delay-300 duration-2000 h-1 w-4/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-8/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-11/12 bg-Success rounded-l",
];
const progressBarHubAR = [
  "transition-all ease-out delay-300 duration-2000 h-1 w-11/12 bg-Brand-50 rounded-l",
  "transition-all ease-out duration-1000 h-1 w-6/12 bg-Brand-50 rounded-l",
  "transition-all ease-out duration-1000 h-1 w-1/12 bg-Brand-50 rounded-l",
];

const progressBar = [
  "h-1 w-0 bg-Success",
  "transition-all ease-out delay-300 duration-2000 h-1 w-1/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-4/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-6/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-11/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-11/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-11/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-12/12 bg-Success rounded-l",
  "transition-all ease-out duration-1000 h-1 w-12/12 bg-Success rounded-l",
];
const progressBarAr = [
  "h-1 w-0 bg-Brand-50",
  "transition-all ease-out delay-300 duration-2000 h-1 w-11/12 bg-Brand-50 ",
  "transition-all ease-out duration-1000 h-1 w-10/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-9/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-8/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-7/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-6/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-5/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-4/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-3/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-2/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-1/12 bg-Brand-50",
  "transition-all ease-out duration-1000 h-1 w-12/12 bg-Brand-50",
];
function OctoHeader({
  variant,
  onBack,
  currentPage,
  showProgress,
  title,
  logo,
}) {
  const ref = createRef();
  const handleOpen = () => {
    ref.current.open();
  };
  switch (variant) {
    case "onboarding":
      return (
        <>
          <div class="w-full md:text-black bg-Neutrals-surface">
            <div
              class={
                locale
                  ? "flex pb-6 items-center justify-between"
                  : "flex flex-row-reverse pb-6 items-center justify-between"
              }
            >
              <div
                onClick={onBack}
                class="w-8 h-8 flex items-center justify-center"
              >
                {locale ? (
                  <img src={ArrowLeftSvg} alt="back arrow" />
                ) : (
                  <img src={ArrowRightSvg} alt="back arrow" />
                )}
              </div>
              <div
                class="flex lg:hidden bg-Secondary-50 rounded-md py-2 px-4 items-center justify-center space-x-3"
                onClick={handleOpen}
              >
                <img alt="need help" loading="lazy" src={HelpSvg} />
                <p
                  class={
                    locale
                      ? "text-Secondary-700 font-semibold text-sm font-arch"
                      : "text-Secondary-700 font-semibold text-sm font-Arabic"
                  }
                >
                  {selectedlang.onboarding.common.need_help}
                </p>
              </div>
              <div class="hidden lg:flex">
                {logo && (
                  <img
                    src={logo ? logo : ""}
                    alt={"merchant logo"}
                    width="48px"
                  />
                )}
              </div>
            </div>
          </div>
          {showProgress ? (
            locale ? (
              <div class="h-1 md:hidden mx-4 bg-Neutrals-50 rounded">
                <div class={progressBar[currentPage]}></div>
              </div>
            ) : (
              <div class="h-1 mx-4 bg-Success md:hidden rounded">
                <div class={progressBarAr[currentPage]}></div>
              </div>
            )
          ) : (
            <></>
          )}
          <NeedHelpActionSheet ref={ref}></NeedHelpActionSheet>
        </>
      );

    case "onboarding_hub":
      return (
        <>
          <div class="w-full md:text-black md:hidden bg-Neutrals-surface">
            <div
              class={
                locale
                  ? "flex pb-6  pt-16 items-center justify-between"
                  : "flex flex-row-reverse pb-6  pt-16 items-center justify-between"
              }
            >
              <div
                onClick={onBack}
                class="w-8 h-8 flex items-center justify-center"
              >
                {locale ? (
                  <img src={ArrowLeftSvg} alt="back arrow" />
                ) : (
                  <img src={ArrowRightSvg} alt="back arrow" />
                )}
              </div>
              <div
                class="bg-Secondary-50 rounded-md py-2 px-4 flex items-center justify-center space-x-3"
                onClick={handleOpen}
              >
                <img alt="need help" loading="lazy" src={HelpSvg} />
                <p
                  class={
                    locale
                      ? "text-Secondary-700 font-semibold text-sm font-arch"
                      : "text-Secondary-700 font-semibold text-sm font-Arabic"
                  }
                >
                  {selectedlang.onboarding.common.need_help}
                </p>
              </div>
            </div>
            {/* <div class={locale ? styles.headerTitle.en : styles.headerTitle.ar}>
                <p class="flex-1 text-2xl font-semibold">
                  {HeaderRegisterTitle[currentPage]}
                </p>
              </div> */}
          </div>
          {showProgress ? (
            locale ? (
              <div class="h-1 md:hidden mx-4 bg-Neutrals-50 rounded">
                <div class={progressBarHub[currentPage]}></div>
              </div>
            ) : (
              <div class="h-1 mx-4 bg-Success md:hidden rounded">
                <div class={progressBarHubAR[currentPage]}></div>
              </div>
            )
          ) : (
            <></>
          )}
          <NeedHelpActionSheet ref={ref}></NeedHelpActionSheet>
        </>
      );

    default:
      break;
  }
}

export default OctoHeader;
