import React, { useEffect } from "react";

import GaugeSvg from "../../../assets/svg/Gauge.svg";
import SearchSvg from "../../../assets/svg/UserSearch.svg";
import IDSvg from "../../../assets/svg/ID.svg";

import { selectedlang } from "../../../services/selectedlanguage";
import { locale } from "../../../services/variables";

function RegisterLimit(props) {
  useEffect(() => {
    setTimeout(() => {
      props.navigate("confirm");
    }, 8000);
  }, []);
  return (
    <div className="px-4 pt-14 text-center">
      <p
        className={`${
          locale ? "font-arch" : "font-Arabic"
        }  text-Title2_RG tracking-wide text-Neutrals-750 font-normal`}
      >
        {selectedlang.onboarding.registrationFunnel.registration8.header}
      </p>
      <div className="my-6 flex items-center space-x-8 justify-center">
        <img alt="gauge" src={GaugeSvg} className="animate-pulse" />
        <img alt="gauge" src={SearchSvg} className="animate-pulse" />
        <img alt="gauge" src={IDSvg} className="animate-pulse" />
      </div>
      <p
        className={`${
          locale ? "font-inter" : "font-Arabic"
        }  text-Neutrals-750 tracking-tight font-normal text-Body1_RG mb-8`}
      >
        {selectedlang.onboarding.registrationFunnel.registration8.text}
        <br />
        {selectedlang.onboarding.registrationFunnel.registration8.text2}
        <br />
        {selectedlang.onboarding.registrationFunnel.registration8.text3}
      </p>
      <p
        className={`${
          locale ? "font-inter" : "font-Arabic"
        }  text-Neutrals-400 font-normal text-Body3_RG tracking-small`}
      >
        {selectedlang.onboarding.registrationFunnel.registration8.text4}
      </p>
    </div>
  );
}

export default RegisterLimit;
