import React from 'react';

function OctoContainer(props) {
    return ( 
        <div class="flex flex-col lg:grid lg:grid-cols-12 bg-neutral-50 min-h-screen w-full lg:justify-center lg:items-center">
            <div class="col-span-1"></div>
            <div class="col-span-10 min-h-3/4">
            {props.children}
            </div>
            <div class="col-span-1"></div>
        </div>
     );
}

export default OctoContainer;