import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  IconButton,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import React, { useState } from "react";
import moment from "moment";
import { locale } from "../../services/variables";
import Mobile from "../../assets/svg/MobileLogin.svg";
import Profile from "../../assets/svg/FullName.svg";
import Email from "../../assets/svg/EmailIcon.svg";
import Calendar from "../../assets/svg/BirthdateCal.svg";
import MoneyBag from "../../assets/svg/MoneyBag.svg";
import Building from "../../assets/svg/BuildingEmp.svg";
import TypeofEmp from "../../assets/svg/TypeofEmp.svg";
import ProBag from "../../assets/svg/ProBag.svg";
import Mail from "../../assets/svg/Email_icon.svg";

function OctoInput({
  variant,
  onChange,
  onClick,
  value,
  disabled,
  placeholder,
  test_id,
  criteria,
  loadLogo,
  type,
  color,
  maxnumber,
  iconPadding,
  PY,
  moneyFlag,
}) {
  const [showPass, setShowPass] = useState(false);
  const cjs = (val) => val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  //const cjs = (val) => val.replace(/^\d+(,\d+)*(\.\d{2})?$/, "$1,");
  const handleShowPass = () => {
    setShowPass(!showPass);
  };
  let selectedIcon = null;
  switch (loadLogo) {
    case "Mobile":
      selectedIcon = Mobile;
      break;
    case "Profile":
      selectedIcon = Profile;
      break;
    case "Email":
      selectedIcon = Email;
      break;
    case "Calendar":
      selectedIcon = Calendar;
      break;
    case "MoneyBag":
      selectedIcon = MoneyBag;
      break;
    case "Building":
      selectedIcon = Building;
      break;
    case "TypeofEmp":
      selectedIcon = TypeofEmp;
      break;
    case "ProBag":
      selectedIcon = ProBag;
      break;
    default:
      break;
  }
  const renderInput = () => {
    switch (variant) {
      case "primary":
        return (
          <InputGroup>
            {loadLogo ? (
              <InputLeftElement
                height="56px"
                width={"56px"}
                paddingStart={locale ? "" : "8px"}
                paddingEnd={locale ? "8px" : ""}
                pointerEvents="none"
                children={
                  <img
                    src={selectedIcon}
                    alt=""
                    loading="lazy"
                    width={"28px"}
                    height={"28px"}
                  />
                }
              />
            ) : (
              <></>
            )}
            <Input
              data-testid={test_id}
              _placeholder={{
                color: color ? color : "#607D8B",
              }}
              variant="unstyled"
              height="56px"
              padding={!loadLogo ? "4" : ""}
              shadow="0px -1px 10px rgba(15, 55, 97, 0.05), 0px 2px 3px rgba(15, 55, 97, 0.06)"
              borderRadius="6px"
              onChange={onChange}
              onClick={onClick}
              value={value}
              // border={criteria ? "2px" : "1px"}
              borderColor={criteria ? "#00B85C" : "#90A4AE"}
              backgroundColor="#FFFFFF"
              dir={locale ? "ltr" : "rtl"}
              placeholder={placeholder}
              type={type ? type : ""}
              min={
                type === "date"
                  ? moment().subtract(65, "years").format("YYYY-MM-DD")
                  : ""
              }
              max={
                type === "date"
                  ? moment().subtract(16, "years").format("YYYY-MM-DD")
                  : ""
              }
              pattern={type === "date" ? "\\d{2}-\\(?<Name>x)-\\d{4}" : ""}
            />
            {criteria ? (
              <InputRightElement
                width="56px"
                height="56px"
                children={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#00B85C"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                }
              />
            ) : (
              <></>
            )}
          </InputGroup>
        );
      case "amount":
        return (
          <InputGroup>
            {loadLogo ? (
              <InputLeftElement
                height="56px"
                width={"56px"}
                paddingStart={locale ? "" : "8px"}
                paddingEnd={locale ? "8px" : ""}
                pointerEvents="none"
                children={
                  <img
                    src={selectedIcon}
                    alt=""
                    loading="lazy"
                    width={"28px"}
                    height={"28px"}
                  />
                }
              />
            ) : (
              <></>
            )}
            <Input
              data-testid={test_id}
              _placeholder={{ color: "#607D8B" }}
              variant="unstyled"
              height="56px"
              padding={!loadLogo ? "4" : ""}
              shadow="0px -1px 10px rgba(15, 55, 97, 0.05), 0px 2px 3px rgba(15, 55, 97, 0.06)"
              borderRadius="6px"
              onChange={onChange}
              onClick={onClick}
              value={value}
              // border={criteria ? "2px" : "1px"}
              borderColor={criteria ? "#00B85C" : "#90A4AE"}
              backgroundColor="white"
              dir={locale ? "ltr" : "rtl"}
              placeholder={placeholder}
            />
            {value ? (
              <InputRightElement
                width="56px"
                height="56px"
                children={
                  locale ? (
                    <p className="text-[#607D8B]">EGP</p>
                  ) : (
                    <p className="text-[#607D8B]">جنيه</p>
                  )
                }
              />
            ) : (
              <></>
            )}
          </InputGroup>
        );
      case "password":
        return (
          <InputGroup>
            <Input
              data-testid="name-input"
              variant="unstyled"
              height="56px"
              padding="4"
              type={showPass ? "text" : "password"}
              shadow="0px 2px 2px rgba(12, 38, 79, 0.1)"
              borderRadius="6px"
              onChange={onChange}
              onClick={onClick}
              class="text-green-600"
              value={value}
              // border={criteria ? "2px" : "1px"}
              borderColor={criteria ? "#00B85C" : "#90A4AE"}
              backgroundColor="white"
              dir={locale ? "ltr" : "rtl"}
              placeholder={placeholder}
            />

            {criteria ? (
              <InputRightElement
                width="112px"
                height="56px"
                children={
                  <div class="flex items-center space-x-2">
                    <IconButton
                      variant="ghost"
                      alignItems={"center"}
                      justifyContent={"center"}
                      onClick={handleShowPass}
                      icon={
                        showPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            />
                          </svg>
                        )
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#00B85C"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                }
              />
            ) : (
              <InputRightElement
                alignItems={"center"}
                height="56px"
                width="56px"
              >
                <IconButton
                  variant="ghost"
                  onClick={handleShowPass}
                  icon={
                    showPass ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )
                  }
                />
              </InputRightElement>
            )}
          </InputGroup>
        );

      case "number":
        return (
          <div class="flex items-center justify-center">
            <InputGroup alignItems={"center"} justifyContent="center">
              <Input
                data-testid={test_id}
                _placeholder={{ color: "#607D8B" }}
                variant="unstyled"
                height="56px"
                fontSize={"56"}
                width={value ? value.length + "ch" : 12}
                lineHeight="62px"
                textAlign={"center"}
                letterSpacing="-0.04em"
                textColor={"#0E5AA9"}
                type={"tel"}
                // padding="4"
                // shadow="0px -1px 10px rgba(15, 55, 97, 0.05), 0px 2px 3px rgba(15, 55, 97, 0.06)"
                // borderRadius="6px"
                onChange={onChange}
                onClick={onClick}
                value={value}
                // alignContent={"center"}
                // alignItems="center"
                // verticalAlign="center"
                // border={criteria ? "2px" : "1px"}
                // borderColor={criteria ? "#00B85C" : "#90A4AE"}
                //backgroundColor="red"
                dir={locale ? "ltr" : "rtl"}
                placeholder={placeholder}
              />
              <p class="text-Body4_RG">EGP</p>
            </InputGroup>
          </div>
        );

      case "Email":
        return (
          <InputGroup>
            {loadLogo ? (
              <InputLeftElement
                height="56px"
                width={"56px"}
                // paddingRight="8px"
                paddingX="10px"
                pointerEvents="none"
                children={<img src={Mail} alt="" loading="lazy" />}
              />
            ) : (
              <></>
            )}
            <Input
              data-testid={test_id}
              _placeholder={{ color: "#607D8B" }}
              variant="unstyled"
              height="56px"
              padding={!loadLogo ? "4" : ""}
              shadow="0px -1px 10px rgba(15, 55, 97, 0.05), 0px 2px 3px rgba(15, 55, 97, 0.06)"
              borderRadius="6px"
              onChange={onChange}
              onClick={onClick}
              value={value}
              // border={criteria ? "2px" : "1px"}
              borderColor={criteria ? "#00B85C" : "#90A4AE"}
              backgroundColor="#FFFFFF"
              dir={locale ? "ltr" : "rtl"}
              placeholder={placeholder}
            />
          </InputGroup>
        );
      case "Bdate":
        return (
          <InputGroup>
            {loadLogo ? (
              <InputLeftElement
                height="56px"
                width={"56px"}
                paddingRight="8px"
                pointerEvents="none"
                children={
                  <img
                    src={selectedIcon}
                    alt=""
                    loading="lazy"
                    width={"28px"}
                    height={"28px"}
                  />
                }
              />
            ) : (
              <></>
            )}
            <Input
              data-testid={test_id}
              _placeholder={{
                color: color ? color : "#607D8B",
                paddingRight: locale ? "" : "8px",
              }}
              variant="unstyled"
              height="56px"
              padding={!loadLogo ? "4" : ""}
              shadow="0px -1px 10px rgba(15, 55, 97, 0.05), 0px 2px 3px rgba(15, 55, 97, 0.06)"
              borderRadius="6px"
              onChange={onChange}
              onClick={onClick}
              value={value}
              // border={criteria ? "2px" : "1px"}
              borderColor={criteria ? "#00B85C" : "#90A4AE"}
              backgroundColor="#FFFFFF"
              dir={locale ? "ltr" : "rtl"}
              placeholder={placeholder}
              type={type ? type : ""}
              // paddingRight="6"
              min={
                type === "date"
                  ? moment().subtract(65, "years").format("YYYY-MM-DD")
                  : ""
              }
              max={
                type === "date"
                  ? moment().subtract(16, "years").format("YYYY-MM-DD")
                  : ""
              }
              pattern={type === "date" ? "\\d{2}-\\(?<Name>x)-\\d{4}" : ""}
            />
            {criteria ? (
              <InputRightElement
                width="56px"
                height="56px"
                children={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#00B85C"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                }
              />
            ) : (
              <></>
            )}
          </InputGroup>
        );

      case "specialNumber":
        return (
          <div class="flex items-center justify-center w-full">
            <NumberInput
              variant="unstyled"
              precision={parseFloat(value) % 1 === 0 ? 0 : 2}
              max={maxnumber}
              onChange={onChange}
              value={cjs(value)}
              keepWithinRange={true}
              min={0}
            >
              <NumberInputField
                minW={value.length === 0 ? "56px" : 0}
                padding={0}
                width={value.length + "ch"}
                maxW={"full"}
                data-testid={test_id}
                _placeholder={{ color: "#607D8B" }}
                height="56px"
                fontSize={"56"}
                lineHeight="62px"
                textAlign={"center"}
                //letterSpacing="-0.04em"
                textColor={"#0E5AA9"}
                onClick={onClick}
                dir={locale ? "ltr" : "rtl"}
                placeholder={placeholder}
              />
            </NumberInput>
            <p class="text-Body4_RG">EGP</p>
          </div>
        );
      case "specialInput":
        return (
          <div
            borderColor={criteria ? "#00B85C" : "#90A4AE"}
            dir={locale ? "ltr" : "rtl"}
            className={`${PY || "py-0.5"} ${
              loadLogo ? "" : "px-4"
            } bg-white flex justify-start items-center gap-2 rounded-md shadow-octo`}
          >
            {loadLogo ? (
              <img
                src={selectedIcon}
                alt=""
                loading="lazy"
                width="28px"
                height="28px"
                className={`${locale ? "ml-4" : "mr-4"} ${
                  iconPadding || "p-1"
                }`}
              />
            ) : (
              <></>
            )}

            <div className="relative z-0 mt-1.5 w-full">
              <input
                onChange={onChange}
                spellCheck={false}
                onClick={onClick}
                value={value}
                id={test_id}
                className={`${
                  locale ? "font-arch" : "text-right font-Arabic"
                } block py-3.5 px-0 w-full text-Body2_RG tracking-tight font-normal text-Neutrals-750 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer`}
                placeholder=""
                type={type || ""}
                dir="ltr"
              />

              {value === "" || value === null || value === undefined ? (
                <label
                  htmlFor={test_id}
                  dir={locale ? "ltr" : "rtl"}
                  className={`${
                    locale
                      ? "font-inter peer-focus:left-0 left-0"
                      : "text-right font-Arabic peer-focus:-right-4"
                  } absolute text-Body3_MD text-neutral-500 font-normal duration-300 transform -translate-y-3 scale-75 top-3 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 -z-10`}
                >
                  {placeholder}
                </label>
              ) : (
                <>
                  {placeholder.includes(" ") ? (
                    <label
                      htmlFor={test_id}
                      dir={locale ? "ltr" : "rtl"}
                      className={`${
                        locale
                          ? "font-inter peer-focus:left-0"
                          : "text-right font-Arabic -right-4"
                      } absolute text-Body3_MD text-neutral-500 font-normal duration-300 transform -translate-y-3 scale-75 top-3 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 z-10`}
                    >
                      {placeholder}
                    </label>
                  ) : (
                    <label
                      htmlFor={test_id}
                      dir={locale ? "ltr" : "rtl"}
                      className={`${
                        locale
                          ? "font-inter peer-focus:left-0"
                          : "text-right font-Arabic -right-2"
                      } absolute text-Body3_MD text-neutral-500 font-normal duration-300 transform -translate-y-3 scale-75 top-3 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 z-10`}
                    >
                      {placeholder}
                    </label>
                  )}
                </>
              )}
            </div>
            {moneyFlag && value != "" ? (
              <>
                {locale ? (
                  <p className="flex justify-end flex-1 mr-4 font-normal tracking-tight transform font-inter text-Body3_RG text-neutral-500">
                    EGP
                  </p>
                ) : (
                  <p className="flex justify-end flex-1 ml-4 font-normal tracking-tight transform font-Arabic text-Body3_RG text-neutral-500">
                    جنيه
                  </p>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div class={locale ? "font-inter w-full" : "font-Arabic w-full"}>
      {renderInput()}
    </div>
  );
}

export default OctoInput;
