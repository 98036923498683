import axios from "axios";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import PinSvg from "../../../assets/svg/PIN.svg";
import OctoButton from "../../../components/OctoButton/OctoButton";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { getHeader, getOctoId } from "../../../services/AuthService";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale, OAG_API_URL } from "../../../services/variables";
import { showErrorMessage } from "../../../services/utils";

function LoginOTP(props) {
  const [otp, setOTP] = useState("");
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ticker, setTicker] = useState(
    setTimeout(() => {
      tick();
    }, 1000)
  );

  const tick = () => {
    if (timerActive && timer !== 0) {
      setTimer(timer - 1);
    }
  };

  const handleChange = (otp) => {
    if (otp.length === 6) {
      sendAmplitudeEvent("KASHIER_OTP_INPUT", {});
      setContinueDisabled(false);
    } else {
      setContinueDisabled(true);
    }
    setOTP(otp);
  };

  const sendOTP = async () => {
    setTimer(60);
    setTimerActive(true);
    setResendDisabled(true);
    axios({
      method: "post",
      headers: await getHeader(),
      url: OAG_API_URL + "kashier/" + props.tokenObj.externalId + "/send_otp/",
      mode: "cors",
      data: {
        octoId: await getOctoId(),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setLoading(false);
        console.log(responseData.data);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  const updateKashierTransaction = async () => {
    axios({
      method: "patch",
      headers: await getHeader(),
      url: OAG_API_URL + "kashier/" + props.tokenObj.externalId + "/",
      mode: "cors",
      data: {
        octoId: await getOctoId(),
        loanPlan: props.selectedPlan.id,
        downPayment: props.downpayment,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        console.log(responseData.data);
        sendOTP();
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  const verifyOTP = async () => {
    setLoading(true);
    axios({
      method: "put",
      headers: await getHeader(),
      url: OAG_API_URL + "kashier/" + props.tokenObj.externalId + "/activate/",
      mode: "cors",
      data: {
        token: otp,
        amount: props.tokenObj.amount,
        downPayment: props.downpayment,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
        }
      })
      .then((responseData) => {
        setLoading(false);
        console.log(responseData.data);
        sendAmplitudeEvent("KASHIER_OTP_SUCCESS", {});
        props.changeCurrentPage();
        props.navigate("/confirm");
      })
      .catch((error) => {
        setLoading(false);
        sendAmplitudeEvent("KASHIER_OTP_FAIL", {});
        showErrorMessage(error);
      });
  };

  useEffect(() => {
    updateKashierTransaction();
    return () => {
      clearInterval(ticker);
    };
  }, []);

  useEffect(() => {
    props.changeCurrentPage(2);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setTimerActive(false);
      setResendDisabled(false);
    }
  }, [timer]);

  return (
    <div class="pt-6 lg:pt-0">
      <div
        class={
          locale
            ? "block lg:flex lg:flex-row lg:items-center"
            : "block lg:flex lg:flex-row-reverse lg:items-center"
        }
      >
        <div class="items-center flex justify-center">
          <img loading="lazy" src={PinSvg} alt="pin" />
        </div>

        <p
          class={
            locale
              ? "text-Title3_MD text-Neutrals-750 lg:flex-1 lg:text-Title2_RG font-medium tracking-tight mb-1 font-arch text-center lg:text-left"
              : "text-Title3_MD text-Neutrals-750 lg:flex-1 lg:text-Title2_RG font-normal font-Arabic tracking-tight mb-1 text-center lg:text-right"
          }
        >
          {selectedlang.onboarding.registrationFunnel.registration3.header}
        </p>
      </div>
      <p
        class={
          locale
            ? "text-center lg:text-left text-Body2_RG lg:text-Body1_RG text-Neutrals-black font-normal tracking-tight font-inter mb-6"
            : "text-center lg:text-right text-Body2_RG text-Neutrals-black font-normal tracking-tight font-Arabic mb-6"
        }
      >
        {selectedlang.onboarding.registrationFunnel.registration3.text}
      </p>
      <div class="my-6">
        <div class="my-6 justify-evenly">
          <OtpInput
            data-testid="otp-input"
            className={{
              borderBottom: "2px solid rgb(207, 216, 220)",
            }}
            value={otp}
            onChange={handleChange}
            numInputs={6}
            containerStyle={{
              width: "100%",
              "justify-content": "space-between",
            }}
            inputStyle={{
              borderBottom: "2px solid rgb(176, 190, 197)",
              height: 50,
              padding: 0,
              width: 44,
              "border-radius": "4px",
              color: "#325083",
              "font-size": "42px",
            }}
            focusStyle={{
              outline: "none",
              borderColor: "#7082A4",
              borderBottom: "2px solid rgb(50, 80, 131)",
            }}
            isInputNum={true}
            isInputSecure={true}
          />
        </div>
        <div
          className={`my-5 font-helv flex-col justify-center items-center`}
          dir={locale ? "ltr" : "rtl"}
        >
          <p
            className={
              locale
                ? "flex justify-center lg:justify-start font-medium font-inter text-Neutrals-750 text-Body3_B tracking-small"
                : "flex justify-center lg:justify-start font-medium font-Arabic text-Neutrals-750 text-Body3_B tracking-small"
            }
          >
            {
              selectedlang.onboarding.registrationFunnel.registration3
                .notReceived
            }
          </p>
          <div className="flex justify-center lg:justify-start">
            <OctoButton
              variant="ghost-sm"
              test_id="continue-btn"
              onClick={() => {
                sendOTP();
              }}
              customColor={timer > 0 ? "#090A0C" : "#116DCF"}
              buttonText={
                timer > 0
                  ? `${selectedlang.onboarding.registrationFunnel.registration3.requestText} ${timer} ${selectedlang.onboarding.registrationFunnel.registration3.secondsText}`
                  : selectedlang.onboarding.registrationFunnel.registration3
                      .resendCodeButton
              }
              disabled={resendDisabled}
            />
          </div>
        </div>
        <div class="mb-8 lg:w-2/3" dir={locale ? "ltr" : "rtl"}>
          <OctoButton
            variant="primary"
            onClick={verifyOTP}
            disabled={continueDisabled}
            buttonText={
              selectedlang.onboarding.registrationFunnel.registration3
                .verifyButton
            }
            Loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginOTP;
