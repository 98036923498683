import moment from "moment";
import { locale } from "./variables";
import { toast } from "react-toastify";

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const showErrorMessage = (error) => {
  return toast.error(
    error?.response?.data?.detail_en
      ? locale
        ? error?.response?.data?.detail_en
        : error?.response?.data?.detail_ar
      : error?.response?.data?.detail
      ? error?.response?.data?.detail
      : locale
      ? "Something went wrong. Please try again later."
      : "خطأ غير محدد",
    {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      bodyClassName: locale ? "text-left" : "text-right",
      rtl: !locale,
    }
  );
  // return error.response.data.detail_en
  //   ? locale
  //     ? error.response.data.detail_en
  //     : error.response.data.detail_ar
  //   : error.response.data.detail
  //   ? error.response.data.detail
  //   : locale
  //   ? "Something went wrong. Please try again later."
  //   : "خطأ غير محدد";
};

export default function ConvertToArabicNumbers(num) {
  const arabicNumbers =
    "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
  return new String(num).replace(/[0123456789]/g, (d) => {
    return arabicNumbers[d];
  });
}

export const ConvertToEnglishNumbers = (inputString) => {
  const arabicDigits = "٠١٢٣٤٥٦٧٨٩";
  const englishDigits = "0123456789";

  for (let i = 0; i < arabicDigits.length; i++) {
    const arabicDigit = arabicDigits[i];
    const englishDigit = englishDigits[i];
    inputString = inputString.replace(
      new RegExp(arabicDigit, "g"),
      englishDigit
    );
  }
  console.log("input", inputString);
  return inputString;
};
