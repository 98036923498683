import React from "react";

function OctoMCQ({ buttonText, variant, onClick, id, option, flag }) {
  const renderInput = () => {
    switch (variant) {
      case "primary":
        return (
          <>
            <div
              class={
                option === "secondary"
                  ? "rounded-md bg-Neutrals-white  shadow-octo  h-12 w-full border-1.5 border-white"
                  : " px-4 py-3.5 rounded-md bg-Neutrals-white  shadow-octo  h-12 border-1.5 border-Secondary-500 w-full"
              }
              id={id}
              onClick={onClick}
            >
              <p
                class={
                  option === "secondary"
                    ? "px-4 py-3.5 font-normal text-Body3_RG text-Neutrals-750 tracking-tight font-inter text-center "
                    : "font-normal text-Body3_RG text-Secondary-500 tracking-tight font-inter text-center"
                }
              >
                {buttonText}
              </p>
            </div>
          </>
        );
      case "secondary":
        return (
          <div class="p-0.5">
            <div
              class={
                option === "secondary"
                  ? " px-4 py-3.5 rounded-md bg-Neutrals-white  shadow-octo  h-12 w-full border-1.5 border-white flex items-center justify-center"
                  : " px-4 py-3.5 rounded-md bg-Neutrals-white  shadow-octo  h-12 border-1.5 border-Secondary-500 w-full flex items-center justify-center"
              }
              id={id}
              onClick={onClick}
            >
              {flag === false ? (
                <p
                  class={
                    option === "secondary"
                      ? "font-normal text-Body3_RG font-inter text-Neutrals-750 tracking-tight"
                      : "font-medium text-Body3_RG font-inter text-Secondary-500 tracking-tight"
                  }
                >
                  {buttonText}
                </p>
              ) : (
                <p
                  class={
                    option === "secondary" && flag === true
                      ? "font-normal text-Number4_RG text-Neutrals-750 tracking-maxWide font-arch"
                      : "font-normal text-Number4_RG text-Secondary-500 tracking-maxWide font-inter"
                  }
                >
                  {buttonText}
                </p>
              )}
              {flag ? (
                <span
                  class={
                    option === "secondary" && flag === true
                      ? "text-Body5_MD text-Neutrals-750 font-inter tracking-tight font-normal pl-103"
                      : "text-Body5_MD text-Secondary-500 font-inter tracking-tight font-normal pl-103"
                  }
                >
                  {" "}
                  EGP
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        );

      case "plans":
        return (
          <>
            <div
              class={
                option === "secondary"
                  ? "rounded-full bg-Neutrals-white h-8 w-full shadow"
                  : "rounded-full bg-Secondary-500 h-12 w-full"
              }
              id={id}
              onClick={onClick}
            >
              <p
                class={
                  option === "secondary"
                    ? " py-1.5 px-3.5 font-arch font-medium tracking-tight text-center text-Number6_B text-Secondary-Secondary"
                    : " py-2 px-5 font-arch font-normal text-center text-Number3_B text-Neutrals-white"
                }
              >
                {buttonText}
              </p>
            </div>
          </>
        );

      default:
        break;
    }
  };

  return <div>{renderInput()}</div>;
}
OctoMCQ.propTypes = {
  //   bodyText: propTypes.string,
  //   late: propTypes.bool,
};
export default OctoMCQ;
