import { Skeleton } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import OctoButton from "../../../components/OctoButton/OctoButton";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { selectedlang } from "../../../services/selectedlanguage";
import { locale, OAG_API_URL } from "../../../services/variables";
import OctoPaymentPlan from "../../../components/OctoPaymentPlan";
import USSVG from "../../../assets/svg/US.svg";
import EGSVG from "../../../assets/svg/EG.svg";
import OctoInput from "../../../components/OctoInput/OctoInput";
import ConvertToArabicNumbers, {
  showErrorMessage,
} from "../../../services/utils";
function PlanScreen(props) {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const [downpayment, setDownpayment] = useState("");
  const changeDownpayment = (event) => {
    const val = event.target.value.replace(/\D/g, "");
    //minimum amount to credit with octo 1000
    if (Number(props.tokenObj.amount) - Number(val) < 1000) {
      setDownpayment(Number(props.tokenObj.amount) - 1000);
      props.changeDownpayment(Number(props.tokenObj.amount) - 1000);
    } else {
      setDownpayment(val);
      props.changeDownpayment(val);
    }
  };

  useEffect(() => {
    const getAvailablePlans = () => {
      axios({
        method: "post",
        url: OAG_API_URL + "plans/calculate/",
        mode: "cors",
        data: {
          amount: props.tokenObj.amount,
        },
      })
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
          }
        })
        .then((responseData) => {
          const plans = responseData.data;
          setAvailablePlans(plans.sort((a, b) => a.id - b.id));
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    };

    if (props.tokenObj.amount) getAvailablePlans();
  }, [props.tokenObj]);
  useEffect(() => {
    const disableCheck = () => {
      if (selectedPlan !== null) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    disableCheck();
  }, [selectedPlan]);

  return (
    <>
      {/* <OctoHeader
        variant={"onboarding"}
        onBack={() => {}}
        currentPage={0}
        logo={props.merchant.icon}
      /> */}
      <div class="flex-1 lg:grid lg:grid-cols-2 pt-6 lg:pt-0">
        {/* <div
          class={
            locale
              ? "flex w-full lg:hidden"
              : "flex flex-row-reverse w-full lg:hidden"
          }
        >
          {locale ? (
            <img
              src={OctoLogo}
              alt="octo logo"
              width={150}
              class="block lg:hidden my-6"
            />
          ) : (
            <img
              src={OctoLogo_ar}
              alt="octo logo"
              width={150}
              class="block lg:hidden my-6"
            />
          )}
        </div> */}
        <div class={locale ? "" : "order-last"}>
          <div class="mb-8 space-y-4">
            <p
              class={
                locale
                  ? "text-Title2_RG text-Neutrals-black font-normal font-arch text-left tracking-small"
                  : "text-Title2_RG text-Neutrals-black font-normal font-Arabic text-right tracking-small"
              }
            >
              {selectedlang.onboarding.registrationFunnel.registration0.header}
            </p>
            <p
              class={
                locale
                  ? "text-Body2_RG tracking-small text-Neutrals-black font-normal font-inter text-left"
                  : "text-Body2_RG tracking-small text-Neutrals-black font-normal font-Arabic text-right"
              }
            >
              {selectedlang.onboarding.registrationFunnel.registration0.text}
              <br />
              <span class="font-semibold">
                {`${props.tokenObj.productName} (${
                  locale
                    ? props.tokenObj.amount
                    : ConvertToArabicNumbers(props.tokenObj.amount)
                } ${locale ? "EGP" : "جنيه"})`}
              </span>
            </p>
          </div>
          {availablePlans.length === 0 ? (
            <Skeleton
              isLoaded={availablePlans.length !== 0}
              rounded="lg"
              height={"144px"}
            />
          ) : (
            <>
              {props.downpaymentFlag ? (
                <>
                  <div
                    className={`text-Body3_B tracking-small font-medium text-Neutrals-500 ${
                      locale ? " font-inter " : " font-Arabic text-right "
                    } `}
                  >
                    {selectedlang.onboarding.downpaymentOptions.inputTitle}
                  </div>
                  <div class="mb-6 mt-3">
                    <OctoInput
                      variant="specialInput"
                      value={downpayment}
                      onChange={changeDownpayment}
                      placeholder={
                        selectedlang.onboarding.downpaymentOptions
                          .inputPlaceholder
                      }
                      disabled={false}
                      loadLogo={"MoneyBag"}
                      color={"#455A64"}
                      moneyFlag={true}
                      PY={"py-0"}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <OctoPaymentPlan
                availablePlans={availablePlans}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
              />
            </>
          )}
          <div
            class={
              locale ? "mt-8 flex w-full" : "mt-8 flex flex-row-reverse w-full"
            }
            // class={locale ? "mt-8 lg:flex" : "mt-8 lg:flex lg:flex-row-reverse"}
          >
            <div class="w-full space-y-4 lg:space-y-0 lg:flex lg:space-x-4">
              <div class="w-full">
                <OctoButton
                  onClick={() => {
                    props.changeSelectedPlan(selectedPlan);
                    props.changeCurrentPage(0);
                    props.navigate("/register/signup/");
                    sendAmplitudeEvent("KASHIER_ONBOARDING_CONTINUE", {
                      selectedPlan: selectedPlan,
                    });
                  }}
                  buttonText={
                    selectedlang.onboarding.registrationFunnel.registration0
                      .getstartedbutton
                  }
                  disabled={disabled}
                  borderRadius="6px"
                  variant="primary"
                />
              </div>
              <div class="w-full">
                <OctoButton
                  onClick={() => {
                    props.changeSelectedPlan(selectedPlan);
                    props.navigate("/login/phone/");
                    props.changeCurrentPage(0);

                    sendAmplitudeEvent("KASHIER_ONBOARDING_LOGIN", {
                      selectedPlan: selectedPlan,
                    });
                  }}
                  buttonText={
                    selectedlang.onboarding.registrationFunnel.registration1
                      .loginBtnText
                  }
                  disabled={disabled}
                  borderRadius="6px"
                  variant="secondary"
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default PlanScreen;
