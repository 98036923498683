////////////////////////////////
//Address Block
//  desc: This component is mainly used to map a list of addresses for atms branches ....etc
//  props:
//        clickable : bool property to allow the user to click on the component and handle redirection to another page
//        changeBranch : function passed from parent component to change the branch
//        changeAddress : function passed from parent component to change the address
//        selectedAddress : The address object to be passed back to the parent component through the change function
//        branch : branch object
//        title : title of the address
//        address : subtitle of the address
//        showArrow : bool property to show an arrow for the user
//        nextPage : function passed from the parent to handle redirect to the next page
//  latest update : 17-08-2022
//  will need refactoring and cleanup later
////////////////////////////////
import React from "react";
import BuildingSvg from "../../assets/svg/Building2.svg";
import { locale } from "../../services/variables";
import RighArrow from "../../assets/svg/ArrowDarkRight.svg";

function AddressBlock({
  clickable,
  changeBranch,
  changeAddress,
  selectedAddress,
  title,
  address,
  branch,
  showArrow,
  nextPage,
  icon,
}) {
  return (
    <>
      {locale ? (
        <div
          class="items-center w-full rounded-md my-4 bg-white p-4 shadow-octo"
          onClick={
            clickable
              ? () => {
                  if (changeBranch) {
                    changeBranch(branch);
                    nextPage();
                  } else {
                    changeAddress(selectedAddress);
                    nextPage();
                  }
                }
              : () => {}
          }
        >
          <div class="flex w-full items-center">
            <div class="mr-3">
              <img className="w-8 h-8" alt="" loading="lazy" src={BuildingSvg} />
            </div>

            <div class="flex-1">
              <p class="text-Neutrals-black text-Body3_B font-medium font-inter tracking-tight mb-1">
                {title !== null ? title : ""}
              </p>
              <p class="text-Neutrals-400 text-Body4_RG font-normal font-inter tracking-tight">
                {address !== null ? address : ""}
              </p>
            </div>
            {showArrow ? (
              <>
                <img
                  src={RighArrow}
                  loading="lazy"
                  alt=""
                  width={16}
                  height={16}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div
          class="items-center w-full rounded-md my-4 bg-white p-4 shadow-octo"
          onClick={
            clickable
              ? () => {
                  if (changeBranch) {
                    changeBranch(branch);
                    nextPage();
                  } else {
                    changeAddress(selectedAddress);
                    nextPage();
                  }
                }
              : () => {}
          }
          dir={locale ? "ltr" : "rtl"}
        >
          <div class="flex w-full items-center">
            <div class="ml-3">
              <img className="w-8 h-8" loading="lazy" src={BuildingSvg} alt="" />
            </div>
            <div class="flex-1 ">
              <p class="text-Body3_B font-medium text-Neutrals-black font-Arabic tracking-tight mb-1">
                {title !== null ? title : ""}
              </p>
              <p class=" text-Body4_RG font-normal text-Neutrals-400 font-Arabic tracking-tight">
                {address !== null ? address : ""}
              </p>
            </div>
            {showArrow ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AddressBlock;
