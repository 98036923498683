import React from "react";
import OctoLogo from "../../logo.svg";
import OctoLogo_ar from "../../assets/svg/logo_ar.svg";

import HelpSvg from "../../assets/svg/Help.svg";

import IDPendingIcon from "../../assets/icons/sidebar/IDPending.svg";
import IDDoneIcon from "../../assets/icons/sidebar/IDDone.svg";
import IDIcon from "../../assets/icons/sidebar/ID.svg";
import MobilePendingIcon from "../../assets/icons/sidebar/MobilePending.svg";
import MobileDoneIcon from "../../assets/icons/sidebar/MobileDone.svg";
import MobileIcon from "../../assets/icons/sidebar/Mobile.svg";
import KeyPendingIcon from "../../assets/icons/sidebar/KeyPending.svg";
import KeyDoneIcon from "../../assets/icons/sidebar/KeyDone.svg";
import KeyIcon from "../../assets/icons/sidebar/Key.svg";
// import HomePendingIcon from "../../assets/icons/sidebar/HomePending.svg";
// import HomeDoneIcon from "../../assets/icons/sidebar/HomeDone.svg";
// import HomeIcon from "../../assets/icons/sidebar/Home.svg";
import BriefcasePendingIcon from "../../assets/icons/sidebar/BriefcasePending.svg";
import BriefcaseDoneIcon from "../../assets/icons/sidebar/BriefcaseDone.svg";
import BriefcaseIcon from "../../assets/icons/sidebar/Briefcase.svg";
import ShoppingPendingIcon from "../../assets/icons/sidebar/ShoppingPending.svg";
import ShoppingDoneIcon from "../../assets/icons/sidebar/ShoppingDone.svg";
import ShoppingIcon from "../../assets/icons/sidebar/Shopping.svg";
import CheckIcon from "../../assets/icons/sidebar/Check.svg";

import { locale } from "../../services/variables";
import { selectedlang } from "../../services/selectedlanguage";

function OctoSidebar(props) {
  const sectionIndex = {
    personal: 0,
    mobile: 1,
    key: 2,
    Briefcase: 3,
    shopping: 4,
  };
  const getIcon = (sectionIndex) => {
    const icons = {
      personal: [IDIcon, IDPendingIcon, IDDoneIcon],
      mobile: [MobileIcon, MobilePendingIcon, MobileDoneIcon],
      key: [KeyIcon, KeyPendingIcon, KeyDoneIcon],
      Briefcase: [BriefcaseIcon, BriefcasePendingIcon, BriefcaseDoneIcon],
      shopping: [ShoppingIcon, ShoppingPendingIcon, ShoppingDoneIcon],
    };

    const sectionKeys = Object.keys(icons);
    return sectionKeys.reduce((acc, key) => {
      if (props.currentPage > sectionIndex[key]) {
        acc[key] = icons[key][2]; // Done icon
      } else if (props.currentPage === sectionIndex[key]) {
        acc[key] = icons[key][1]; // Pending icon
      } else {
        acc[key] = icons[key][0]; // Default icon
      }
      return acc;
    }, {});
  };
  const renderSidebar = () => {
    const pathname = props.location.pathname;
    const icons = getIcon(sectionIndex);

    if (pathname === "/" || pathname.includes("/welcome")) {
      return (
        <div
          class={
            locale
              ? "space-y-5 font-inter text-Body3_RG"
              : "text-right space-y-6 font-Arabic text-Body3_RG"
          }
        >
          <div
            class={
              props.currentPage > 0
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 0
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <p>{selectedlang.onboarding.sidebar.welcome}</p>
            {props.currentPage > 0 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 1
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 1
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <p>{selectedlang.onboarding.sidebar.paymentmethod}</p>
            {props.currentPage > 1 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 2
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 2
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <p>{selectedlang.onboarding.sidebar.paymentplan}</p>
            {props.currentPage > 2 && <img src={CheckIcon} alt="check" />}
          </div>
        </div>
      );
    } else if (
      pathname.includes("/register") ||
      pathname.includes("/overview")
    ) {
      return (
        <div
          class={
            locale
              ? "space-y-5 font-inter text-Body3_RG"
              : "text-right space-y-6 font-Arabic text-Body3_RG"
          }
        >
          <div
            class={
              props.currentPage > 0
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 0
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <img src={icons.personal} alt="icon" />
            <p>{selectedlang.onboarding.sidebar.personal}</p>
            {props.currentPage > 0 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 1
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 1
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <img src={icons.mobile} alt="icon" />

            <p>{selectedlang.onboarding.sidebar.verification}</p>
            {props.currentPage > 1 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 2
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 2
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <img src={icons.key} alt="icon" />
            <p>{selectedlang.onboarding.sidebar.security}</p>
            {props.currentPage > 2 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 3
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 3
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <img src={icons.Briefcase} alt="icon" />
            <p>{selectedlang.onboarding.sidebar.residenceEmployment}</p>
            {props.currentPage > 3 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 4
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 4
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <img src={icons.shopping} alt="icon" />
            <p>{selectedlang.onboarding.sidebar.creditcheck}</p>
            {props.currentPage > 4 && <img src={CheckIcon} alt="check" />}
          </div>
        </div>
      );
    } else if (pathname.includes("/login")) {
      return (
        <div
          class={
            locale
              ? "space-y-5 font-inter text-Body3_RG"
              : "text-right space-y-6 font-Arabic text-Body3_RG"
          }
        >
          <div
            class={
              props.currentPage > 0
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 0
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <p>{selectedlang.onboarding.sidebar.login}</p>
            {props.currentPage > 0 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 1
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 1
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <p>{selectedlang.onboarding.sidebar.verify}</p>
            {props.currentPage > 1 && <img src={CheckIcon} alt="check" />}
          </div>
          <div
            class={
              props.currentPage > 2
                ? "text-Success flex items-center space-x-1 gap-x-1"
                : props.currentPage === 2
                ? "text-Secondary-700 flex items-center space-x-1 gap-x-1"
                : "text-Neutrals-black flex items-center space-x-1 gap-x-1"
            }
          >
            <p>{selectedlang.onboarding.sidebar.details}</p>
            {props.currentPage > 2 && <img src={CheckIcon} alt="check" />}
          </div>
        </div>
      );
    }
  };
  return (
    <div
      class={`hidden ${
        locale ? "" : "lg:flex-row-reverse"
      } lg:flex lg:flex-col lg:col-span-3 p-4 lg:p-4 bg-white w-full justify-between shadow-sidebar z-10`}
      dir={locale ? "ltr" : "rtl"}
    >
      <img src={locale ? OctoLogo : OctoLogo_ar} alt="octo logo" width={130} />
      {props.currentPage >= 0 ? renderSidebar() : <></>}
      <div
        class="bg-Secondary-50 rounded-md py-2 px-4 flex items-center justify-center space-x-3 gap-3"
        onClick={props.onNeedHelp}
      >
        <img alt="need help" loading="lazy" src={HelpSvg} />
        <p
          class={
            locale
              ? "text-Secondary-700 font-semibold text-sm font-arch"
              : "text-Secondary-700 font-semibold text-sm font-Arabic"
          }
        >
          {selectedlang.onboarding.common.need_help}
        </p>
      </div>
    </div>
  );
}

export default OctoSidebar;
