import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import OctoHeader from "../../../components/OctoHeader/OctoHeader";
import { locale } from "../../../services/variables";
import ErrorScreen from "../../error/ErrorScreen";
import PlanScreen from "./plan";
import DownpaymentOptionScreen from "./downpayment";

function WelcomeIndexScreen(props) {
  const navigate = useNavigate();
  const tokenObj = props.tokenObj;

  const prevPage = () => {
    navigate(-1);
  };

  return props.tokenFlag === true ? (
    <>
      <OctoHeader
        variant={"onboarding"}
        onBack={prevPage}
        logo={props.merchant.icon}
        // showProgress={true}
      />
      <div
        class={
          //todo check this conditioned design
          // location.pathname !== "/register/start/"
          // ? "flex-1 lg:grid lg:grid-cols-2"
          // :
          "flex-1 lg:grid lg:grid-cols-1"
        }
      >
        <div class={locale ? "col-span-1" : "col-span-1 order-last"}>
          <Routes>
            <Route
              path="downpayment"
              element={
                <DownpaymentOptionScreen
                  merchant={props.merchant}
                  tokenObj={tokenObj}
                  changeCurrentPage={props.changeCurrentPage}
                  changeSelectedPlan={props.changeSelectedPlan}
                  changeDownpaymentFlag={props.changeDownpaymentFlag}
                  // setDownpaymentFlag={setDownpaymentFlag}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="plan"
              element={
                <PlanScreen
                  merchant={props.merchant}
                  tokenObj={tokenObj}
                  changeCurrentPage={props.changeCurrentPage}
                  changeSelectedPlan={props.changeSelectedPlan}
                  changeDownpayment={props.changeDownpayment}
                  navigate={navigate}
                  downpaymentFlag={props.downpaymentFlag}
                />
              }
            />
          </Routes>
        </div>
        <div></div>
      </div>
    </>
  ) : (
    <ErrorScreen></ErrorScreen>
  );
}

export default WelcomeIndexScreen;
