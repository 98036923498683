import { Menu, MenuButton, MenuItem, MenuList, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { locale } from "../../services/variables";
import ArrowDown from "../../assets/svg/ArrowDown_selected.svg";
import TypeofEmp from "../../assets/svg/TypeofEmp.svg";
import ProBag from "../../assets/svg/ProBag.svg";
import TimeRev from "../../assets/svg/TimeRev.svg";
import AddressType from "../../assets/svg/AddressType.svg";
import City from "../../assets/svg/City.svg";
import Area from "../../assets/svg/Area.svg";

function OctoSelect({
  onChange,
  data,
  value,
  placeholder,
  test_id,
  optionKey,
  loadLogo,
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    console.log("valueinselect", value);
  }, [value]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  let selectedIcon = null;
  switch (loadLogo) {
    case "TypeofEmp":
      selectedIcon = TypeofEmp;
      break;
    case "ProBag":
      selectedIcon = ProBag;
      break;
    case "time":
      selectedIcon = TimeRev;
      break;
    case "address":
      selectedIcon = AddressType;
      break;
    case "city":
      selectedIcon = City;
      break;
    case "area":
      selectedIcon = Area;
      break;
    default:
      break;
  }
  return (
    <div
      dir={locale ? "ltr" : "rtl"}
      className="bg-white flex justify-start items-center gap-2 px-4 rounded-md shadow-octo"
    >
      {selectedIcon && (
        <img
          src={selectedIcon}
          alt=""
          loading="lazy"
          width="28px"
          height="28px"
          // className={`${locale ? " pl-4" : "mr-4"}`}
        />
      )}
      <Menu>
        <MenuButton as="div" className="relative z-0 pl-0 w-full border-none">
          <div
            className={
              locale
                ? "flex-1 items-center justify-center border-none "
                : "flex-1 items-center justify-center pr-1"
            }
          >
            <Select
              className="focus:ring-0 transform translate-y-1 "
              iconSize="0px"
              value={value}
              onChange={onChange}
              onClick={toggleOpen}
              dir={locale ? "ltr" : "rtl"}
              variant="unstyled"
              border="none"
              height="56px"
              // icon={<img src={ArrowDown} class="" alt="" loading="lazy" />}
              style={value !== "" ? { color: "#252525" } : { color: "#455A64" }}
            >
              {data ? (
                <>
                  <option />
                  {data?.map((option, index) => (
                    <option
                      key={index}
                      value={option[optionKey]}
                      data-testid={`option-${index}`}
                    >
                      {locale ? option.name_en : option.name_ar}
                    </option>
                  ))}
                </>
              ) : (
                <option>{value}</option>
              )}
            </Select>
          </div>
          {value === "" || value == null ? (
            <label
              htmlFor={test_id}
              dir={locale ? "ltr" : "rtl"}
              className={`${
                locale
                  ? "font-inter peer-focus:left-0"
                  : "text-right font-Arabic peer-focus:-right-4 "
              } absolute text-Body3_MD text-neutral-500 font-normal duration-300 transform -translate-y-3 top-8 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 -z-10`}
            >
              {placeholder}
            </label>
          ) : (
            <label
              htmlFor={test_id}
              dir={locale ? "ltr" : "rtl"}
              className={`${
                locale ? "font-inter " : "font-Arabic -right-3 "
              } absolute text-Body3_MD text-neutral-500 font-normal duration-300 transform -translate-y-3 scale-75 top-5 origin-[0] z-10`}
            >
              {placeholder}
            </label>
          )}
          <img
            width="16px"
            className={`absolute ${locale ? "right-0" : "left-0"} top-6`}
            src={ArrowDown}
            alt="null"
          />
        </MenuButton>
        <MenuList maxHeight="40vh" overflowY="auto">
          {data ? (
            <>
              <MenuItem />
              {data?.map((option, index) => (
                <MenuItem
                  value={option[optionKey]}
                  key={index}
                  onClick={onChange}
                >
                  {locale ? option.name_en : option.name_ar}
                </MenuItem>
              ))}
            </>
          ) : (
            <MenuItem>{value}</MenuItem>
          )}
        </MenuList>
      </Menu>
    </div>
  );
}

export default OctoSelect;

// import { Select } from "@chakra-ui/react";
// import React from "react";
// import { locale } from "../../services/variables";
// import ArrowDown from "../../assets/svg/ArrowDown_selected.svg";
// import TypeofEmp from "../../assets/svg/TypeofEmp.svg";
// import ProBag from "../../assets/svg/ProBag.svg";
// import TimeRev from "../../assets/svg/TimeRev.svg";
// import AddressType from "../../assets/svg/AddressType.svg";
// import City from "../../assets/svg/City.svg";
// import Area from "../../assets/svg/Area.svg";

// function OctoSelect({
//   onChange,
//   data,
//   value,
//   placeholder,
//   test_id,
//   optionKey,
//   loadLogo,
// }) {
//   let selectedIcon = null;
//   switch (loadLogo) {
//     case "TypeofEmp":
//       selectedIcon = TypeofEmp;
//       break;
//     case "ProBag":
//       selectedIcon = ProBag;
//       break;
//     case "time":
//       selectedIcon = TimeRev;
//       break;
//     case "address":
//       selectedIcon = AddressType;
//       break;
//     case "city":
//       selectedIcon = City;
//       break;
//     case "area":
//       selectedIcon = Area;
//       break;
//     default:
//       break;
//   }
//   const renderInput = () => {
//     return (
//       <div
//         class={
//           locale
//             ? "bg-Neutrals-white rounded-md px-3 flex items-center shadow-octo"
//             : "bg-Neutrals-white rounded-md px-3 flex flex-row-reverse items-center shadow-octo"
//         }
//       >
//         <div class="flex items-center justify-start">
//           {selectedIcon && (
//             <img
//               src={selectedIcon}
//               alt=""
//               width={"22px"}
//               height={"22px"}
//               loading="lazy"
//             />
//           )}
//         </div>
//         <div
//           class={
//             locale
//               ? "flex-1 items-center justify-center"
//               : "flex-1 items-center justify-center pr-2"
//           }
//         >
//           <Select
//             value={value}
//             onChange={onChange}
//             dir={locale ? "ltr" : "rtl"}
//             variant={"unstyled"}
//             height={"56px"}
//             icon={<img src={ArrowDown} alt="" loading="lazy" />}
//             style={value !== "" ? { color: "#252525" } : { color: "#455A64" }}
//           >
//             <option selected hidden disabled value="">
//               {placeholder}
//             </option>

//             {data.map((option, index) => (
//               <option
//                 key={index}
//                 value={option[optionKey]}
//                 data-testid={"option-" + index}
//                 // class="items-center justify-center"
//               >
//                 {locale ? option.name_en : option.name_ar}
//               </option>
//             ))}
//           </Select>
//         </div>
//       </div>
//     );
//   };
//   return (
//     <div class={locale ? "font-inter w-full" : "font-Arabic w-full"}>
//       {renderInput()}
//     </div>
//   );
// }

// export default OctoSelect;
