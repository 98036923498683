import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import OctoHeader from "../../../components/OctoHeader/OctoHeader";
import LoginOTP from "./loginOTP";
import LoginPhone from "./loginPhone";
import LoginPin from "./loginPin";
import { locale } from "../../../services/variables";
import ErrorScreen from "../../error/ErrorScreen";

function LoginIndexScreen(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const changePhone = (phone) => {
    sessionStorage.setItem("phone", phone);
    setPhone(phone);
  };
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else {
      navigate(-1);
    }
  };

  return props.tokenFlag === true ? (
    <>
      <OctoHeader
        variant={"onboarding"}
        onBack={prevPage}
        currentPage={currentPage}
        logo={props.merchant.icon}
      />
      <div class="flex-1 lg:grid lg:grid-cols-2">
        <div class={locale ? "col-span-1" : "col-span-1 order-last"}>
          <Routes>
            <Route
              path="phone"
              element={
                <LoginPhone
                  navigate={navigate}
                  setCheckpoint={props.setCheckpoint}
                  changePhone={changePhone}
                  nextPage={nextPage}
                  Login={props.Login}
                  setLoading={props.setLoading}
                  changeCurrentPage={props.changeCurrentPage}
                />
              }
            ></Route>
            <Route
              path="pin"
              element={
                <LoginPin
                  navigate={navigate}
                  setCheckpoint={props.setCheckpoint}
                  phone={phone}
                  nextPage={nextPage}
                  Login={props.Login}
                  history={props.history}
                  setLoading={props.setLoading}
                  changeCurrentPage={props.changeCurrentPage}
                />
              }
            ></Route>
            <Route
              path="verification"
              element={
                <LoginOTP
                  selectedPlan={props.selectedPlan}
                  downpayment={props.downpayment}
                  tokenObj={props.tokenObj}
                  navigate={navigate}
                  setCheckpoint={props.setCheckpoint}
                  phone={phone}
                  nextPage={nextPage}
                  Login={props.Login}
                  history={props.history}
                  setLoading={props.setLoading}
                  changeCurrentPage={props.changeCurrentPage}
                />
              }
            ></Route>
          </Routes>
        </div>
        <div></div>
      </div>
    </>
  ) : (
    <ErrorScreen></ErrorScreen>
  );
}

export default LoginIndexScreen;
