import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import sendAmplitudeEvent from "../../../services/Amplitude";
import { locale, OAG_API_URL } from "../../../services/variables";
import OtpInput from "react-otp-input";
import OctoNumpad from "../../../components/OctoNumpad/OctoNumpad";
import LockSvg from "../../../assets/svg/Code_lock.svg";
import { selectedlang } from "../../../services/selectedlanguage";
import { getHeader, getOctoId } from "../../../services/AuthService";
import moment from "moment";
import { showErrorMessage } from "../../../services/utils";

function RegisterPIN(props) {
  const [PIN, setPIN] = useState("");
  const [confirmPIN, setConfirmPIN] = useState("");
  const [donewithPin, setDoneWithPIN] = useState(false);

  const handlePinChange = (pin) => {
    setPIN(pin);
  };
  const handleConfirmPinChange = (pin) => {
    setConfirmPIN(pin);
  };
  console.log(props);
  useEffect(() => {
    if (PIN !== "" && PIN.length === 6 && !isNaN(PIN)) {
      setDoneWithPIN(true);
    } else {
      setDoneWithPIN(false);
    }
  }, [PIN]);
  useEffect(() => {
    props.changeCurrentPage(2);
  }, []);
  useEffect(() => {
    const disableCheck = () => {
      if (
        PIN.length === 6 &&
        PIN === confirmPIN &&
        /^[1-9][0-9]{5}$/.test(PIN) &&
        /^[1-9][0-9]{5}$/.test(confirmPIN)
      ) {
        createOctoUser();
      }
    };
    disableCheck();
  }, [confirmPIN, PIN]);

  const createOctoUser = () => {
    if (PIN === confirmPIN) {
      axios({
        method: "post",
        url: OAG_API_URL + "user/app/",
        mode: "cors",
        data: {
          phoneNumber: "+2" + props.phone,
          password: PIN,
          email: props.email,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
          }
        })
        .then((responseData) => {
          console.log(responseData.data);
          sendAmplitudeEvent("KASHIER_PIN_USER_CREATED", {
            octo_id: responseData.data.id,
          });

          sessionStorage.setItem("octo-access", responseData.data.access);
          sessionStorage.setItem("octo-refresh", responseData.data.refresh);
          console.log(responseData.data);
          axios({
            method: "post",
            url: OAG_API_URL + "users/" + responseData.data.id + "/profile/",
            mode: "cors",
            headers: {
              Authorization: "Bearer " + responseData.data.access,
            },
            data: {
              firstName: props.fname,
              lastName: props.lname,
              lang: locale ? 1 : 0,
              phoneNumber: "+2" + props.phone,
              email: props.email,
              userType: 4, //user type credit
            },
          })
            .then((response) => {
              console.log(response);
              if (response.status >= 200 && response.status < 300) {
                return response;
              } else {
              }
            })
            .then(async (responseData) => {
              sendAmplitudeEvent("KASHIER_PASSWORD_PROFILE_CREATED", {
                octo_id: responseData.data.id,
              });
              console.log(responseData.data);
              const octoId = await getOctoId();
              axios({
                method: "post",
                url: OAG_API_URL + "users/" + octoId + "/legal_profile/",
                mode: "cors",
                headers: await getHeader(),
                data: {
                  birthday: moment(props.birthdate).format("YYYY-MM-DD"),
                },
              })
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    return response;
                  } else {
                  }
                })
                .then((responseData) => {
                  sendAmplitudeEvent("KASHIER_BIRTHDATE_SUCCESS", {
                    octo_id: octoId,
                  });
                  console.log(responseData);
                })
                .catch((error) => {
                  showErrorMessage(error);
                  sendAmplitudeEvent("KASHIER_IRTHDATE_FAIL", {
                    octo_id: octoId,
                  });
                });
            })
            .then(() => {
              props.changeCurrentPage(3);
              props.navigate("employment");
            })
            .catch((error) => {
              showErrorMessage(error);
            });
        })
        .catch((error) => {
          showErrorMessage(error);
          setPIN("");
          setConfirmPIN("");
          setDoneWithPIN(false);
          //   setDisabled(true);
        });
    } else {
      toast.error("PINs do not match,Please try again!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
      setPIN("");
      setConfirmPIN("");
      setDoneWithPIN(false);
      //   setDisabled(true);
    }
  };

  return (
    <div>
      {!donewithPin ? (
        <div class="pt-6 lg:pt-0">
          <div class="w-16 h-16 mb-7 items-center mx-auto">
            <img
              width={"80px"}
              height={"80px"}
              src={LockSvg}
              alt=""
              loading="lazy"
            />
          </div>
          <div
            class={`text-Title3_MD text-Neutrals-750 tracking-tight font-medium ${
              locale ? "font-arch" : "font-Arabic"
            } text-center`}
          >
            <p>
              {selectedlang.onboarding.registrationFunnel.registration4.header}
            </p>
          </div>

          <div class="my-8">
            <div class="my-4 justify-evenly">
              <OtpInput
                data-testid="pin-input"
                className={{
                  borderBottom: "2px solid rgb(50, 80, 131,1)",
                }}
                value={PIN}
                onChange={handlePinChange}
                numInputs={6}
                containerStyle={{
                  width: "100%",
                  "justify-content": "space-between",
                }}
                inputStyle={{
                  // padding: 0,
                  borderBottom: "2px solid rgb(176, 190, 197,1)",
                  height: 50,
                  padding: 0,
                  width: 44,
                  "border-radius": "6px",
                  color: "#0C264F",
                  "font-size": "42px",
                }}
                focusStyle={{
                  outline: "none",
                  borderBottom: "2px solid rgb(50, 80, 131)",
                }}
                isInputNum={true}
                isInputSecure={true}
              />
            </div>

            <div class="mt-8">
              <OctoNumpad onChange={handlePinChange} value={PIN} />
            </div>
          </div>
        </div>
      ) : (
        <div class="pt-6 lg:pt-0">
          <div class="w-16 h-16 mb-7 items-center mx-auto">
            <img
              width={"80px"}
              height={"80px"}
              src={LockSvg}
              alt=""
              loading="lazy"
            />
          </div>
          <div
            class={`text-Title3_MD text-Neutrals-750 tracking-tight font-medium ${
              locale ? "font-arch" : "font-Arabic"
            } text-center`}
          >
            <p>
              {selectedlang.onboarding.registrationFunnel.registration4.header2}
            </p>
          </div>
          <div class="my-8">
            <div class="my-4 justify-evenly">
              <OtpInput
                data-testid="pin-input"
                className={{
                  borderBottom: "2px solid rgb(50, 80, 131,1)",
                }}
                value={confirmPIN}
                onChange={handleConfirmPinChange}
                numInputs={6}
                containerStyle={{
                  width: "100%",
                  "justify-content": "space-between",
                }}
                inputStyle={{
                  borderBottom: "2px solid rgb(176, 190, 197,1)",
                  height: 50,
                  padding: 0,
                  width: 44,
                  "border-radius": "6px",
                  color: "#0C264F",
                  "font-size": "42px",
                }}
                focusStyle={{
                  outline: "none",
                  borderBottom: "2px solid rgb(50, 80, 131)",
                }}
                isInputNum={true}
                isInputSecure={true}
              />
            </div>
            <div class="mt-8">
              <OctoNumpad
                value={confirmPIN}
                onChange={handleConfirmPinChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterPIN;
