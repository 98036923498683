import React from "react";
import { selectedlang } from "../../services/selectedlanguage";
import { locale } from "../../services/variables";
import ConfettiSvg from "../../assets/svg/Confetti.svg";

const HubConfirm = (props) => {
  setTimeout(() => {
    window.close();
  }, 5000);
  return (
    <div class="pt-6 lg:pt-0">
      <div
        className={`flex items-center justify-center mt-5 ${
          locale
            ? "lg:items-start lg:justify-start"
            : "lg:items-end lg:justify-end"
        }`}
      >
        <img alt="congrats" src={ConfettiSvg} width={100} height={120} />
      </div>
      <>
        <p
          class={
            locale
              ? "text-center lg:text-left text-Title2_RG font-normal text-Neutrals-750 font-arch tracking-small mb-2"
              : "text-center lg:text-right text-Title2_RG font-normal text-Neutrals-750 font-Arabic tracking-small mb-2"
          }
        >
          {selectedlang.onboarding.registrationFunnel.confirm.header}
        </p>
        <p
          class={
            locale
              ? "text-center lg:text-left text-Body2_RG font-normal text-Neutrals-750 font-inter tracking-small"
              : "text-center lg:text-right text-Body2_RG font-normal text-Neutrals-750 font-Arabic tracking-small"
          }
        >
          {selectedlang.onboarding.registrationFunnel.confirm.text}
        </p>
      </>
    </div>
  );
};

export default HubConfirm;
