////////////////////////////////
//Appointment block
//  desc: This component is mainly used in to display a generated appointments list for the user
//  props:
//        showArrow : show an arrow for the component
//        date : date string
//        time : time string
//
//  latest update : 17-08-2022
////////////////////////////////
import moment from "moment";
import React from "react";
import CalendarSvg from "../../assets/svg/Calendar2.svg";
import "moment/locale/ar";
import "moment/locale/en-gb";
import { locale } from "../../services/variables";

function AppointmentBlock({ showArrow, date, time }) {
  // console.log(moment(time, "hh:mm A").locale("ar").format("h:mm A"));
  const determineAMorPMinArabic = (time) => {
    let isAM = moment(time, "hh:mm A").locale("en-gb").format("A") === "AM";
    let formattedTime = moment(time, "hh:mm A").locale("ar");
    let amPmIndicator = isAM ? "ص" : "م";
    return `${formattedTime.format("hh:mm")} ${amPmIndicator}`;
  };

  return (
    <>
      {locale ? (
        <div class="items-center w-full shadow-octo rounded-md my-4 bg-white px-4 py-3">
          <div class="flex w-full items-center">
            <div class="flex h-10 w-10 rounded-md items-center justify-center mr-3">
              <img alt="" loading="lazy" src={CalendarSvg} />
            </div>

            <div class="flex-1 ">
              <p class="text-Neutrals-750 text-Body3_B font-inter font-medium tracking-tight mb-1">
                {time}
              </p>
              {date ? (
                <p class="text-Neutrals-400 text-Body3_B tracking-tight font-normal font-inter">
                  {moment(date).format("dddd DD MMM YYYY")}
                </p>
              ) : (
                <div />
              )}
              {/* <p class="text-Neutrals-400 text-normal font-normal font-inter">
                Book Appointment
              </p> */}
            </div>
            {showArrow ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div class="items-center w-full shadow-octo rounded-md my-4 bg-white px-4 py-3">
          <div class="flex w-full items-center">
            {showArrow ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </>
            ) : (
              <></>
            )}

            <div class="flex-1 text-right">
              <p className="mb-1 text-Body3_B font-medium font-Arabic tracking-tight text-Neutrals-750">
                {determineAMorPMinArabic(time)}
              </p>
              {date !== "" ? (
                <p className="font-Arabic font-normal text-Body3_B tracking-tight text-neutral-400 ">
                  {date
                    ? moment(date).locale("ar").format("dddd DD MMMM YYYY")
                    : ""}
                </p>
              ) : (
                <div />
              )}

              {/* <p class="text-Neutrals-400 text-normal font-normal font-Arabic">حجز موعد</p> */}
            </div>
            <div class="flex h-10 w-10 rounded-md items-center justify-center ml-4">
              <img loading="lazy" src={CalendarSvg} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AppointmentBlock;
