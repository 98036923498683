import React from "react";
import { useNavigate } from "react-router-dom";
import AlertIcon from "../../assets/svg/Alert.svg";
import OctoHeader from "../../components/OctoHeader/OctoHeader";
import { locale } from "../../services/variables";
function ErrorScreen(props) {
  const navigate = useNavigate();
  return (
    <>
      <OctoHeader
        variant={"onboarding"}
        onBack={() => {
          navigate(-1);
        }}
        currentPage={0}
        //showProgress
        logo={props.merchant?.icon}
      />
      <div class="flex flex-col items-center justify-center text-center h-full pt-10 px-4 lg:p-0">
        <img src={AlertIcon} alt="error" />
        <p class="text-Title1_RG font-arch my-6">{locale ? "404" : "٤٠٤"}</p>
        <p
          class={
            locale ? "text-Body2_RG font-inter" : "text-Body2_RG font-Arabic"
          }
        >
          {locale
            ? "Something doesn't look right, please go back"
            : "هناك شيء لا يبدو على ما يرام، يرجى العودة إلى الصفحة السابقة"}
        </p>
      </div>
    </>
  );
}

export default ErrorScreen;
