import React from "react";
import ArrowRight from "../../assets/svg/Arrowrightsmall.svg";
import { locale } from "../../services/variables";
import FeeBadge from "../FeeBadge/FeeBadge";

function PaymentOptionBlock({
  active,
  icon,
  fee = null,
  title,
  subtitle,
  percentage = false,
  onClick,
  noArrow,
}) {
  return (
    <div
      className={
        active
          ? "h-full"
          : "relative bg-Neutrals-white rounded-md shadow-octo h-full"
      }
      dir={locale ? "ltr" : "rtl"}
    >
      <div className={active ? "h-full" : "z-10 inset-0 opacity-50 h-full"}>
        <div
          onClick={active ? onClick : undefined}
          className="items-center my-4 rounded-md shadow-octo bg-Neutrals-white"
        >
          <div
            className={`flex items-center w-full p-4 ${
              locale ? "space-x-4 " : " gap-4 "
            }`}
          >
            <div className="flex items-center justify-center w-10 h-10">
              <img alt="" loading="lazy" src={icon} />
            </div>

            <div className="flex-1 font-medium text-normal text-Neutrals-black">
              <div className="flex">
                <p
                  className={`${
                    active
                      ? "font-medium tracking-tight text-Neutrals-750 text-Body3_MD "
                      : " "
                  } ${locale ? "pr-2 font-inter " : " pl-2 font-Arabic"}`}
                >
                  {title}
                </p>
                {fee !== null && <FeeBadge fee={fee} percentage={percentage} />}
              </div>
              <p
                className={`${
                  active
                    ? "pt-1 font-normal tracking-tight text-Body4_RG text-Neutrals-500 "
                    : "text-normal text-Neutrals-400 "
                }  ${locale ? " font-inter " : " font-Arabic"}`}
              >
                {subtitle}
              </p>
            </div>

            {!noArrow && (
              <div className="flex">
                <img
                  alt=""
                  loading="lazy"
                  src={ArrowRight}
                  className={locale ? "" : "transform rotate-180"}
                  width={16}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentOptionBlock;
